import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "./Contact Details.css";
import ConfirmationModal from "../../../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  createContactDetails_stage1_API,
  getAllCountryDataAPI,
  getContactDetails_stage1_API,
} from "../../../../api";
import { useLocation } from "react-router-dom";
import Loader from "../../../Loader/Loader";

const ContactDetails = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [countriesData, setCountriesData] = useState([]);

  useEffect(() => {
    // Fetch countries when component mounts
    const fetchCountries = async () => {
      try {
        const response = await getAllCountryDataAPI();
        if (response.data?.response && response.data?.response?.data) {
          setCountriesData(response.data.response.data);
        } else {
          console.error(
            "Error fetching countries:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const [termsChecked, setTermsChecked] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [alternateEmail, setAlternateEmail] = useState("");
  const [alternateEmailError, setAlternateEmailError] = useState("");
  const [unitFlatNumber, setUnitFlatNumber] = useState("");
  const [streatLotNumber, setStreatLotNumber] = useState("");
  const [streatName, setStreatName] = useState("");
  const [suburbCity, setSuburbCity] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [postCode, setPostCode] = useState("");
  const [mobileCountryCode, setMobileCountryCode] = useState("13");
  const [mobileNumber, setMobileNumber] = useState("");
  const [alternateMobileCountryCode, setAlternateMobileCountryCode] =
    useState("13");
  const [alternateMobileNumber, setAlternateMobileNumber] = useState("");

  const [emergencyMobileCountryCode, setEmergencyMobileCountryCode] =
    useState("13");
  const [emergencyMobileNumber, setEmergencyMobileNumber] = useState("");
  const [emergencyFirstName, setEmergencyFirstName] = useState("");
  const [emergencySurname, setEmergencySurname] = useState("");
  const [emergencyRelationship, setEmergencyRelationship] = useState("");
  const [country, setCountry] = useState("");
  const [postalCountry, setPostalCountry] = useState("");
  const [unitFlatNumberPostal, setUnitFlatNumberPostal] = useState("");
  const [streatLotNumberPostal, setStreatLotNumberPostal] = useState("");
  const [streatNamePostal, setStreatNamePostal] = useState("");
  const [suburbCityPostal, setSuburbCityPostal] = useState("");
  const [stateProvincePostal, setStateProvincePostal] = useState("");
  const [postCodePostal, setPostCodePostal] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationBackModal, setShowConfirmationBackModal] =
    useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const handleConfirmationBackNo = () => {
    setShowConfirmationBackModal(false);
  };
  const handleConfirmationBackYes = () => {
    navigate("/user/create_new_application/stage_1/personal_details", {
      state: { pointerData },
    });
    setShowConfirmationBackModal(false);
    toast.success("Navigated to Personal Details form page.");
  };

  const handleBack = () => {
    // setBackButtonClicked(true);
    // setShowConfirmationBackModal(true);

    navigate("/user/create_new_application/stage_1/personal_details", {
      state: { pointerData },
    });
    toast.success("Navigated to Personal Details form page.");
  };

  const handleEmailChange = (event) => {
    const input = event.target.value;

    setEmail(input);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(input)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleAlternateEmailChange = (event) => {
    const input = event.target.value.trim();
    setAlternateEmail(input);
  
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!emailPattern.test(input) && input !== "") {
      setAlternateEmailError("Please enter a valid alternate email address.");
      return;
    }
  
    if (email.trim() === input) {
      setAlternateEmailError("Email matches exactly with the alternate email.");
    } else {
      setAlternateEmailError("");
    }
  };
  

  const handleNext = async (e) => {
    e.preventDefault();

    // if (termsChecked) {
    //   setUnitFlatNumberPostal(unitFlatNumber);
    //   setStreatLotNumberPostal(streatLotNumber);
    //   setStreatNamePostal(streatName);
    //   setSuburbCityPostal(suburbCity);
    //   setStateProvincePostal(stateProvince);
    //   setPostCodePostal(postCode);
    //   setPostalCountry(country);
    // }

    if (!termsChecked) {
      if (postalCountry.trim() !== "") {
      } else {
        setPostalCountry("");
      }

      if (unitFlatNumberPostal.trim() !== "") {
      } else {
        setUnitFlatNumberPostal("");
      }

      if (streatLotNumberPostal.trim() !== "") {
      } else {
        setStreatLotNumberPostal("");
      }

      if (streatNamePostal.trim() !== "") {
      } else {
        setStreatNamePostal("");
      }

      if (suburbCityPostal.trim() !== "") {
      } else {
        setSuburbCityPostal("");
      }

      if (stateProvincePostal.trim() !== "") {
      } else {
        setStateProvincePostal("");
      }
      if (postCodePostal.trim() !== "") {
      } else {
        setPostCodePostal("");
      }
    }

    if (country.trim() === "") {
      toast.error("Please select your country.");
      document.getElementById("formBasicCountrySelect").focus();

      return;
    }

    if (streatLotNumber.trim() === "") {
      toast.error("Please enter your street / lot number.");
      document.getElementById("streetLotNumber").focus();

      return;
    }

    if (streatName.trim() === "") {
      toast.error("Please enter your street name.");
      document.getElementById("streetName").focus();

      return;
    }

    if (suburbCity.trim() === "") {
      toast.error("Please enter your suburb / city.");
      document.getElementById("suburbCity").focus();

      return;
    }

    if (stateProvince.trim() === "") {
      toast.error("Please enter your state / province.");
      document.getElementById("stateProvince").focus();

      return;
    }

    if (postCode.trim() === "") {
      toast.error("Please enter your postcode.");
      document.getElementById("Postcode").focus();

      return;
    }

    if (email.trim() === "") {
      toast.error("Please enter your email.");
      document.getElementById("email").focus();

      return;
    }

    // if (alternateEmail.trim() === "") {
    //   toast.error("Please enter your alternate email.");
    //   document.getElementById("alternateEmail").focus();

    //   return;
    // }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email.trim())) {
      toast.error("Please enter a valid email address.");
      document.getElementById("email").focus();

      return;
    }

    // if (!emailPattern.test(alternateEmail.trim())) {
    //   toast.error("Please enter your valid alternate email address.");
    //   document.getElementById("alternateEmail").focus();

    //   return;
    // }

    if (email.trim() === alternateEmail.trim()) {
      toast.error("Email exactly matched with alternate email");
      document.getElementById("email").focus();
      document.getElementById("alternateEmail").focus();

      return;
    }

    if (mobileCountryCode.trim() === "") {
      toast.error("Please select your mobile country code.");
      document.getElementById("mobileCountryCode").focus();

      return;
    }
    if (mobileNumber.trim() === "") {
      toast.error("Please enter your mobile number.");
      document.getElementById("mobileNumber").focus();

      return;
    }

    if (mobileNumber === alternateMobileNumber) {
      toast.error("Mobile number exactly matched with alternate mobile number");
      document.getElementById("mobileNumber").focus();
      document.getElementById("alternateMobileNumber").focus();
      return;
    }

    if (
      alternateMobileNumber.trim() !== "" &&
      alternateMobileCountryCode.trim() === ""
    ) {
      toast.error("Please select alternate mobile country code.");
      document.getElementById("alternateMobileCountryCode").focus();
      return;
    }

    if (!termsChecked) {
      if (postalCountry.trim() === "") {
        toast.error("Please select your postal country.");
        document.getElementById("formBasicSelectPostalCountry").focus();

        return;
      }
      if (streatLotNumberPostal.trim() === "") {
        toast.error("Please enter your postal street / lot number.");
        document.getElementById("StreetLotNumberPostal").focus();

        return;
      }

      if (streatNamePostal.trim() === "") {
        toast.error("Please enter your postal street name.");
        document.getElementById("formBasicStreetNamePostal").focus();

        return;
      }

      if (suburbCityPostal.trim() === "") {
        toast.error("Please enter your postal suburb / city.");
        document.getElementById("formBasicSuburbCityPostal").focus();

        return;
      }

      if (stateProvincePostal.trim() === "") {
        toast.error("Please enter your postal state / province.");
        document.getElementById("formBasicStateProvincePostal").focus();

        return;
      }

      if (postCodePostal.trim() === "") {
        toast.error("Please enter your postal postcode.");
        document.getElementById("formBasicPostcodeNumberPostal").focus();

        return;
      }
    }

    if (emergencyFirstName.trim() === "") {
      toast.error("Please enter your emergency contact first name.");
      document.getElementById("emergencyFirstNameInput").focus();

      return;
    }

    if (emergencySurname.trim() === "") {
      toast.error("Please enter your emergency contact surname.");
      document.getElementById("emergencySurnameInput").focus();

      return;
    }

    if (emergencyRelationship.trim() === "") {
      toast.error("Please enter your emergency contact relationship.");
      document.getElementById("emergencyRelationshipInput").focus();

      return;
    }

    if (emergencyMobileCountryCode.trim() === "") {
      toast.error("Please select your  emergency contact country code.");
      document.getElementById("emergencyMobileCountryCode").focus();

      return;
    }

    if (emergencyMobileNumber.trim() === "") {
      toast.error("Please enter your emergency contact mobile number.");
      document.getElementById("emergencyMobileNumber").focus();

      return;
    }

    // navigate("/user/create_new_application/stage_1/identification", { state: { pointerData } });
    // toast.success("Contact details saved successfully.");

    // Construct form data object
    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append("emergency_mobile_code", emergencyMobileCountryCode);
    formData.append("alter_mobile_code", alternateMobileCountryCode);
    formData.append("mobile_number_code", mobileCountryCode);
    formData.append("country", country);
    formData.append("building_prop_name", "");
    formData.append("unit_flat_number", unitFlatNumber);
    formData.append("street_lot_number", streatLotNumber);
    formData.append("street_name", streatName);
    formData.append("suburb", suburbCity);
    formData.append("state_proviance", stateProvince);
    formData.append("postcode", postCode);
    formData.append("mobile_number", mobileNumber);
    formData.append("alter_mobile", alternateMobileNumber);
    formData.append("email", email);
    formData.append("alternate_email", alternateEmail);
    formData.append("postal_address_is_different", termsChecked ? "yes" : "no");
    formData.append("Postal_country", termsChecked ? country : postalCountry);
    formData.append("postal_building_prop_name", "");
    formData.append(
      "postal_unit_flat_number",
      termsChecked ? unitFlatNumber : unitFlatNumberPostal
    );
    formData.append(
      "postal_street_lot_number",
      termsChecked ? streatLotNumber : streatLotNumberPostal
    );
    formData.append(
      "postal_street_name",
      termsChecked ? streatName : streatNamePostal
    );
    formData.append(
      "postal_suburb",
      termsChecked ? suburbCity : suburbCityPostal
    );
    formData.append(
      "postal_state_proviance",
      termsChecked ? stateProvince : stateProvincePostal
    );
    formData.append(
      "postal_postcode",
      termsChecked ? postCode : postCodePostal
    );
    formData.append("first_name", emergencyFirstName);
    formData.append("surname", emergencySurname);
    formData.append("relationship", emergencyRelationship);
    formData.append("emergency_mobile", emergencyMobileNumber);

    // API call to save contact details
    try {
      setLoading(true);

      const response = await createContactDetails_stage1_API(formData);
      setLoading(false);

      if (response?.data?.response?.response === true) {
        navigate("/user/create_new_application/stage_1/identification", {
          state: { pointerData },
        });
        toast.success("Contact details saved successfully.");
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Contact Details"
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Error saving Contact Details:", error);
      toast.error("Failed to save Contact Details");
    }
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationYes =async () => {
    // navigate("/user/dashboard");
    // setShowConfirmationModal(false);
    // toast.success("Contact details saved successfully.");






        // Construct form data object
        const formData = new FormData();
        formData.append("pointer_id", pointerData?.pointer_id);
        formData.append("emergency_mobile_code", emergencyMobileCountryCode);
        formData.append("alter_mobile_code", alternateMobileCountryCode);
        formData.append("mobile_number_code", mobileCountryCode);
        formData.append("country", country);
        formData.append("building_prop_name", "");
        formData.append("unit_flat_number", unitFlatNumber);
        formData.append("street_lot_number", streatLotNumber);
        formData.append("street_name", streatName);
        formData.append("suburb", suburbCity);
        formData.append("state_proviance", stateProvince);
        formData.append("postcode", postCode);
        formData.append("mobile_number", mobileNumber);
        formData.append("alter_mobile", alternateMobileNumber);
        formData.append("email", email);
        formData.append("alternate_email", alternateEmail);
        formData.append("postal_address_is_different", termsChecked ? "yes" : "no");
        formData.append("Postal_country", termsChecked ? country : postalCountry);
        formData.append("postal_building_prop_name", "");
        formData.append(
          "postal_unit_flat_number",
          termsChecked ? unitFlatNumber : unitFlatNumberPostal
        );
        formData.append(
          "postal_street_lot_number",
          termsChecked ? streatLotNumber : streatLotNumberPostal
        );
        formData.append(
          "postal_street_name",
          termsChecked ? streatName : streatNamePostal
        );
        formData.append(
          "postal_suburb",
          termsChecked ? suburbCity : suburbCityPostal
        );
        formData.append(
          "postal_state_proviance",
          termsChecked ? stateProvince : stateProvincePostal
        );
        formData.append(
          "postal_postcode",
          termsChecked ? postCode : postCodePostal
        );
        formData.append("first_name", emergencyFirstName);
        formData.append("surname", emergencySurname);
        formData.append("relationship", emergencyRelationship);
        formData.append("emergency_mobile", emergencyMobileNumber);
    
        // API call to save contact details
        try {
          setLoading(true);
    
          const response = await createContactDetails_stage1_API(formData);
          setLoading(false);
    
          if (response?.data?.response?.response === true) {

            navigate("/user/dashboard");
    setShowConfirmationModal(false);
    toast.success("Contact details saved successfully.");

          } else {
            // If API call fails, display error message
            toast.error(
              response?.data?.response?.error_msg ||
                "Failed to save Contact Details"
            );
          }
        } catch (error) {
          setLoading(false);
    
          console.error("Error saving Contact Details:", error);
          toast.error("Failed to save Contact Details");
        }





  };

  const handleSaveAndExit = async () => {
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    const getPersonalDetailsFunction = async () => {
      try {
        setLoading(true);
        const response = await getContactDetails_stage1_API(
          pointerData?.pointer_id
        );
        setLoading(false);

        if (
          response &&
          response?.data &&
          response?.data?.response &&
          response?.data?.response?.data
        ) {
          const stage_1_contactDetails =
            response?.data?.response?.data?.contact_details;

          if (
            stage_1_contactDetails.postal_address_is_different === "no" ||
            stage_1_contactDetails.postal_address_is_different === ""
          ) {
            setTermsChecked(false);
          } else if (
            stage_1_contactDetails.postal_address_is_different === "yes"
          ) {
            setTermsChecked(true);
          }

          if (stage_1_contactDetails.mobile_number_code === null) {
            setMobileCountryCode("13");
          } else {
            setMobileCountryCode(stage_1_contactDetails.mobile_number_code);
          }

          if (stage_1_contactDetails.alter_mobile_code === null) {
            setAlternateMobileCountryCode("13");
          } else {
            setAlternateMobileCountryCode(
              stage_1_contactDetails.alter_mobile_code
            );
          }

          if (stage_1_contactDetails.emergency_mobile_code === null) {
            setEmergencyMobileCountryCode("13");
          } else {
            setEmergencyMobileCountryCode(
              stage_1_contactDetails.emergency_mobile_code
            );
          }

          if (stage_1_contactDetails.country === "") {
            setCountry("Australia");
          } else {
            setCountry(stage_1_contactDetails.country);
          }

          if (stage_1_contactDetails.Postal_country === "") {
            setPostalCountry("Australia");
          } else {
            setPostalCountry(stage_1_contactDetails.Postal_country);
          }

          setUnitFlatNumber(stage_1_contactDetails.unit_flat_number);
          setStreatLotNumber(stage_1_contactDetails.street_lot_number);
          setStreatName(stage_1_contactDetails.street_name);
          setSuburbCity(stage_1_contactDetails.suburb);
          setStateProvince(stage_1_contactDetails.state_proviance);
          setPostCode(stage_1_contactDetails.postcode);
          setMobileNumber(stage_1_contactDetails.mobile_number);
          setAlternateMobileNumber(stage_1_contactDetails.alter_mobile);
          setEmail(stage_1_contactDetails.email);
          setAlternateEmail(stage_1_contactDetails.alternate_email);
          setUnitFlatNumberPostal(
            stage_1_contactDetails.postal_unit_flat_number
          );
          setStreatLotNumberPostal(
            stage_1_contactDetails.postal_street_lot_number
          );
          setStreatNamePostal(stage_1_contactDetails.postal_street_name);
          setSuburbCityPostal(stage_1_contactDetails.postal_suburb);
          setStateProvincePostal(stage_1_contactDetails.postal_state_proviance);
          setPostCodePostal(stage_1_contactDetails.postal_postcode);
          setEmergencyFirstName(stage_1_contactDetails.first_name);
          setEmergencySurname(stage_1_contactDetails.surname);
          setEmergencyRelationship(stage_1_contactDetails.relationship);
          setEmergencyMobileNumber(stage_1_contactDetails.emergency_mobile);
        } else {
          setLoading(false);

          console.error(
            "Error fetching occupations data:",
            response?.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        setLoading(false);

        console.error("Error fetching occupations data:", error);
      }
    };

    if (pointerData?.pointer_id) {
      getPersonalDetailsFunction(pointerData?.pointer_id);
    }
  }, [pointerData?.pointer_id]);

  return (
    <>
      <div className="forgot-container-contact-details">
        <Card className="shadow forgot-card-contact-details">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Contact Details</b>
          </div>

          <Card.Body>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 d-flex justify-content-end">
                <div className="mb-1 mt-1">
                  Portal Reference No. :{" "}
                  {/* <b style={{ color: "#055837" }}>24AQ063</b> */}
                  <b style={{ color: "#055837" }}>
                    {pointerData?.portal_refrance_no}
                  </b>
                </div>
              </div>
            </div>

            {/* Contact Details */}

            <Card.Body>
              <Form onSubmit={handleNext}>
                <div className="account-details">
                  {/* <div
                    className="text-center"
                    style={{ fontSize: "20px", color: "#055837"}}
                  >
                    <h4>Residential Address</h4>
                  </div>
                  <br></br> */}

                  <div style={{ marginTop: "0px" }}>
                    <Card className="shadow">
                      <div
                        className="card-header text-center"
                        style={{ fontSize: "20px", color: "#055837" }}
                      >
                        <b>Residential Address</b>
                      </div>
                      <Card.Body>
                        <Form onSubmit={handleNext}>
                          <div className="row mb-0">
                            <div className="col-md-6">
                              <Form.Group controlId="formBasicCountryLabel">
                                <Form.Label htmlFor="formBasicCountrySelect">
                                  ▸ Country{" "}
                                  <span style={{ color: "red" }}>*</span>{" "}
                                </Form.Label>

                                <div className="form-group">
                                  <Form.Group controlId="formBasicCountrySelect">
                                    <Form.Select
                                      id="formBasicCountrySelect"
                                      value={country}
                                      onChange={(e) =>
                                        setCountry(e.target.value)
                                      }
                                      style={{cursor:"pointer"}}
                                    >
                                      <option
                                        className="dropdown-options"
                                        value=""
                                        disabled
                                        defaultValue
                                      >
                                        Select Country
                                      </option>

                                      {/* Map over countryyyyyyyyyyyyyys to generate options */}
                                      {countriesData.map(
                                        (countryyyyyyyyyyyyyy) => (
                                          <option
                                            key={countryyyyyyyyyyyyyy.id}
                                            className="dropdown-options"
                                            value={countryyyyyyyyyyyyyy.name}
                                          >
                                            {countryyyyyyyyyyyyyy.name}
                                          </option>
                                        )
                                      )}
                                    </Form.Select>
                                  </Form.Group>
                                </div>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="row mb-0">
                            <div className="col-md-6">
                              <Form.Group controlId="formBasicUnit/FlatNumber">
                                <Form.Label> ▸ Unit / Flat Number</Form.Label>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="Unit / Flat Number"
                                    name="Unit / Flat Number"
                                    placeholder="Enter your Unit / Flat Number"
                                    value={unitFlatNumber}
                                    onChange={(e) =>
                                      setUnitFlatNumber(e.target.value)
                                    }
                                    style={{ height: "40px" }}
                                  />
                                </div>
                              </Form.Group>
                            </div>

                            <div className="col-md-6">
                              <Form.Group controlId="formBasicStreetLotNumber">
                                <Form.Label htmlFor="streetLotNumber">
                                  ▸ Street / Lot number{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="streetLotNumber"
                                    name="streetLotNumber"
                                    placeholder="Enter your Street / Lot number"
                                    value={streatLotNumber}
                                    onChange={(e) =>
                                      setStreatLotNumber(e.target.value)
                                    }
                                    style={{ height: "40px" }}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="row mb-0">
                            <div className="col-md-6">
                              <Form.Group controlId="formBasicStreetName">
                                <Form.Label htmlFor="streetName">
                                  ▸ Street Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="streetName"
                                    name="streetName"
                                    placeholder="Enter your Street Name"
                                    value={streatName}
                                    onChange={(e) =>
                                      setStreatName(e.target.value)
                                    }
                                    style={{ height: "40px" }}
                                  />
                                </div>
                              </Form.Group>
                            </div>

                            <div className="col-md-6">
                              <Form.Group controlId="formBasicSuburbCity">
                                <Form.Label htmlFor="suburbCity">
                                  ▸ Suburb / City{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className={`form-control`}
                                    id="suburbCity"
                                    name="suburbCity"
                                    placeholder="Enter your Suburb / City"
                                    value={suburbCity}
                                    onChange={(e) =>
                                      setSuburbCity(e.target.value)
                                    }
                                    style={{ height: "auto" }}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="row mb-0">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="stateProvince">
                                  ▸ State / Province{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="stateProvince"
                                  name="stateProvince"
                                  placeholder="Enter your State / Province"
                                  value={stateProvince}
                                  onChange={(e) =>
                                    setStateProvince(e.target.value)
                                  }
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <Form.Group controlId="formBasicPostCode">
                                <Form.Label>
                                  ▸ Postcode{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="Postcode"
                                    name="Postcode"
                                    placeholder="Enter your Postcode"
                                    value={postCode}
                                    onChange={(e) =>
                                      setPostCode(e.target.value)
                                    }
                                    style={{ height: "40px" }}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="row mb-0">
                            <div className="col-md-6">
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label>
                                  ▸ Email{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <div className="form-group">
                                  <input
                                    type="email"
                                    className={`form-control ${
                                      emailError ? "is-invalid" : ""
                                    }`}
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    style={{ height: "40px" }}
                                  />
                                  {emailError && (
                                    <div className="invalid-feedback">
                                      {emailError}
                                    </div>
                                  )}
                                </div>
                              </Form.Group>
                            </div>

                            <div className="col-md-6">
                              <Form.Group controlId="formBasicAlternateEmail">
                                <Form.Label>
                                  ▸ Alternate Email{" "}
                                  {/* <span style={{ color: "red" }}>*</span> */}
                                </Form.Label>
                                <div className="form-group">
                                  <input
                                    type="email"
                                    className={`form-control ${
                                      alternateEmailError ? "is-invalid" : ""
                                    }`}
                                    id="alternateEmail"
                                    name="alternateEmail"
                                    placeholder="Enter your alternate email"
                                    value={alternateEmail}
                                    onChange={handleAlternateEmailChange}
                                    style={{ height: "40px" }}
                                  />
                                  {alternateEmailError && (
                                    <div className="invalid-feedback">
                                      {alternateEmailError}
                                    </div>
                                  )}
                                </div>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="account-details">
                            <div className="row mb-0">
                              <div className="col-md-6">
                                <Form.Group controlId="formBasicMobile">
                                  <Form.Label>
                                    Mobile{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Form.Label>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <Form.Group controlId="formBasicMobile">
                                        <Form.Label
                                          className="small"
                                          htmlFor="mobileCountryCode"
                                        >
                                          ▸ Country Code
                                        </Form.Label>
                                        <select
                                          id="mobileCountryCode"
                                          className="form-select"
                                          style={{ height: "40px",cursor:"pointer" }}
                                          value={mobileCountryCode}
                                          onChange={(e) =>
                                            setMobileCountryCode(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            Select Country Code
                                          </option>
                                          {/* Map over countryyyyyyyyyyyyyys to generate options */}
                                          {countriesData.map(
                                            (countryyyyyyyyyyyyyy) => (
                                              <option
                                                key={countryyyyyyyyyyyyyy.phonecode}
                                                className="dropdown-options"
                                                    // value={countryyyyyyyyyyyyyy.phonecode}
                                                    value={countryyyyyyyyyyyyyy.id}
                                              >
                                                {countryyyyyyyyyyyyyy.name} (+
                                                {countryyyyyyyyyyyyyy.phonecode}
                                                )
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </Form.Group>
                                    </div>

                                    <div className="col-md-6">
                                      <Form.Group controlId="formBasicMobileNumber">
                                        <Form.Label
                                          className="small"
                                          htmlFor="mobileNumber"
                                        >
                                          ▸ Number
                                        </Form.Label>
                                        <input
                                          id="mobileNumber"
                                          type="number"
                                          className="form-control"
                                          placeholder="Enter Your Phone Number"
                                          style={{ height: "40px" }}
                                          value={mobileNumber}
                                          onChange={(e) =>
                                            setMobileNumber(e.target.value)
                                          }
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </Form.Group>
                              </div>
                              <div className="col-md-6">
                                <Form.Group controlId="formBasicAlternateMobile">
                                  <Form.Label>Alternate Mobile</Form.Label>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <Form.Group controlId="alternateMobileCountryCodeLabel">
                                        <Form.Label
                                          className="small"
                                          htmlFor="alternateMobileCountryCode"
                                        >
                                          ▸ Country Code
                                        </Form.Label>
                                        <select
                                          id="alternateMobileCountryCode"
                                          className="form-select"
                                          style={{ height: "40px",cursor:"pointer" }}
                                          value={alternateMobileCountryCode}
                                          onChange={(e) =>
                                            setAlternateMobileCountryCode(
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="">
                                            Select Country Code
                                          </option>
                                          {/* Map over countryyyyyyyyyyyyyys to generate options */}
                                          {countriesData.map(
                                            (countryyyyyyyyyyyyyy) => (
                                              <option
                                                key={countryyyyyyyyyyyyyy.phonecode}
                                                className="dropdown-options"
                                                // value={countryyyyyyyyyyyyyy.phonecode}
                                                value={countryyyyyyyyyyyyyy.id}
                                              >
                                                {countryyyyyyyyyyyyyy.name} (+
                                                {countryyyyyyyyyyyyyy.phonecode}
                                                )
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </Form.Group>
                                    </div>

                                    <div className="col-md-6">
                                      <Form.Group controlId="formBasicAlternateMobileNumber">
                                        <Form.Label
                                          className="small"
                                          htmlFor="alternateMobileNumber"
                                        >
                                          ▸ Number
                                        </Form.Label>
                                        <input
                                          id="alternateMobileNumber"
                                          type="number"
                                          className="form-control"
                                          placeholder="Enter Your Phone Number"
                                          style={{ height: "40px" }}
                                          value={alternateMobileNumber}
                                          onChange={(e) =>
                                            setAlternateMobileNumber(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Form.Group>
                                    </div>
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </Card.Body>
                    </Card>
                  </div>
                </div>

                <div className="account-details">
                  <div
                    className="form-group form-check mb-3"
                    style={{
                      marginTop: "35px",
                      display: "flex",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <label
                      className="form-check-label"
                      htmlFor="termsAndConditions"
                      style={{ fontSize: "15px", fontWeight: "bold" }}
                    >
                        ▸ Is your Postal Address the same as your Residential Address ?{"  "}
                      <span style={{ color: "red" }}>*</span>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="termsAndConditions"
                        name="termsAndConditions"
                        checked={termsChecked}
                        // onChange={() => setTermsChecked(!termsChecked)}
                        onChange={(e) => setTermsChecked(e.target.checked)}
                        style={{
                          transform: "scale(1.5)",
                          marginLeft: "20px",
                          cursor:"pointer",
                      color: "#055837",
                      backgroundColor: termsChecked ? "#055837" : "",
                      border: "1px solid #055837",

                        }}
                      />
                    </label>
                  </div>

                  {/* Postal Address Contact Details */}
                  {!termsChecked && (
                    <div style={{ marginTop: "25px" }}>
                      <Card className="shadow">
                        <div
                          className="card-header text-center"
                          style={{ fontSize: "20px", color: "#055837" }}
                        >
                          <b>Postal Address Details</b>
                        </div>

                        <Card.Body>
                          <Form onSubmit={handleNext}>
                            <div className="account-details">
                              <div className="row mb-0">
                                <div className="col-md-6">
                                  <Form.Group controlId="formBasicPostalCountry">
                                    <Form.Label>
                                      ▸ Country{" "}
                                      <span style={{ color: "red" }}>*</span>{" "}
                                    </Form.Label>
                                    <div className="form-group">
                                      <Form.Group controlId="formBasicSelectPostalCountry">
                                        <Form.Select
                                          id="formBasicSelectPostalCountry"
                                          value={postalCountry}
                                          onChange={(e) =>
                                            setPostalCountry(e.target.value)
                                          }
                                          style={{cursor:"pointer"}}

                                        >
                                          <option
                                            className="dropdown-options"
                                            value=""
                                            disabled
                                            defaultValue
                                          >
                                            Select Country
                                          </option>
                                          {/* Map over countryyyyyyyyyyyyyys to generate options */}
                                          {countriesData.map(
                                            (countryyyyyyyyyyyyyy) => (
                                              <option
                                                key={countryyyyyyyyyyyyyy.id}
                                                className="dropdown-options"
                                                value={countryyyyyyyyyyyyyy.name}
                                              >
                                                {countryyyyyyyyyyyyyy.name}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Form.Group>
                                    </div>
                                  </Form.Group>
                                </div>
                              </div>

                              <div className="row mb-0">
                                <div className="col-md-6">
                                  <Form.Group controlId="formBasicFormNumberPostal">
                                    <Form.Label>
                                      {" "}
                                      ▸ Unit / Flat Number
                                    </Form.Label>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="Unit / Flat Number"
                                        name="Unit / Flat Number"
                                        placeholder="Enter your Unit / Flat Number"
                                        value={unitFlatNumberPostal}
                                        onChange={(e) =>
                                          setUnitFlatNumberPostal(
                                            e.target.value
                                          )
                                        }
                                        style={{ height: "40px" }}
                                      />
                                    </div>
                                  </Form.Group>
                                </div>

                                <div className="col-md-6">
                                  <Form.Group controlId="formBasicStreetNumberPostal">
                                    <Form.Label>
                                      ▸ Street / Lot number{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Form.Label>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="StreetLotNumberPostal"
                                        name="StreetLotNumberPostal"
                                        placeholder="Enter your Street / Lot number"
                                        value={streatLotNumberPostal}
                                        onChange={(e) =>
                                          setStreatLotNumberPostal(
                                            e.target.value
                                          )
                                        }
                                        style={{ height: "40px" }}
                                      />
                                    </div>
                                  </Form.Group>
                                </div>
                              </div>
                            </div>

                            <div className="row mb-0">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="formBasicStreetNamePostal">
                                    ▸ Street Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="formBasicStreetNamePostal"
                                    name="formBasicStreetNamePostal"
                                    placeholder="Enter your Street Name"
                                    value={streatNamePostal}
                                    onChange={(e) =>
                                      setStreatNamePostal(e.target.value)
                                    }
                                    style={{ height: "40px" }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <Form.Group controlId="formBasicSuburbCityPostal">
                                  <Form.Label>
                                    ▸ Suburb / City{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Form.Label>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className={`form-control`}
                                      id="formBasicSuburbCityPostal"
                                      name="formBasicSuburbCityPostal"
                                      placeholder="Enter your Suburb / City"
                                      value={suburbCityPostal}
                                      onChange={(e) =>
                                        setSuburbCityPostal(e.target.value)
                                      }
                                      style={{ height: "auto" }}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="row mb-0">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="formBasicStateProvincePostal">
                                    ▸ State / Province{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="formBasicStateProvincePostal"
                                    name="formBasicStateProvincePostal"
                                    placeholder="Enter your State / Province"
                                    value={stateProvincePostal}
                                    onChange={(e) =>
                                      setStateProvincePostal(e.target.value)
                                    }
                                    style={{ height: "40px" }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <Form.Group controlId="formBasicPostcodeNumberPostal">
                                  <Form.Label>
                                    ▸ Postcode{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Form.Label>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className={`form-control`}
                                      id="formBasicPostcodeNumberPostal"
                                      name="formBasicPostcodeNumberPostal"
                                      placeholder="Enter your Postcode"
                                      value={postCodePostal}
                                      onChange={(e) =>
                                        setPostCodePostal(e.target.value)
                                      }
                                      style={{ height: "auto" }}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          </Form>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </div>

                <div style={{ marginTop: "25px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Emergency Contact Details</b>
                    </div>
                    <Card.Body>
                      <Form onSubmit={handleNext}>
                        <div className="row mb-0">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="emergencyFirstNameInput">
                                ▸ First Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="emergencyFirstNameInput"
                                name="emergencyFirstNameInput"
                                placeholder="Enter First Name"
                                value={emergencyFirstName}
                                onChange={(e) =>
                                  setEmergencyFirstName(e.target.value)
                                }
                                style={{ height: "40px" }}
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <Form.Group controlId="formBasiSurnamePostal">
                              <Form.Label htmlFor="emergencySurnameInput">
                                ▸ Surname{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className={`form-control`}
                                  id="emergencySurnameInput"
                                  name="Suburb / City"
                                  placeholder="Enter Surname"
                                  value={emergencySurname}
                                  onChange={(e) =>
                                    setEmergencySurname(e.target.value)
                                  }
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row mb-0 mt-0">
                          <div className="col-md-6 mb-2">
                            <Form.Group controlId="formBasicStreatPostal">
                              <Form.Label htmlFor="emergencyRelationshipInput">
                                ▸ Relationship{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <input
                                type="text"
                                className="form-control"
                                id="emergencyRelationshipInput"
                                name="Relationship"
                                placeholder="Enter Relationship"
                                value={emergencyRelationship}
                                onChange={(e) =>
                                  setEmergencyRelationship(e.target.value)
                                }
                                style={{ height: "40px" }}
                              />
                            </Form.Group>
                          </div>

                          <div className="col-md-6">
                            <Form.Group controlId="formBasicMobileEmergency">
                              {/* <Form.Label>
                                Mobile <span style={{ color: "red" }}>*</span>
                              </Form.Label> */}
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="emergencyMobileCountryCode">
                                      ▸ Mobile Country Code
                                    </label>
                                    <select
                                      id="emergencyMobileCountryCode"
                                      className="form-select"
                                      style={{ height: "40px", cursor:"pointer"}}
                                      value={emergencyMobileCountryCode}
                                      onChange={(e) =>
                                        setEmergencyMobileCountryCode(
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">
                                        Select Country Code
                                      </option>
                                      {/* Map over countryyyyyyyyyyyyyys to generate options */}
                                      {countriesData.map(
                                        (countryyyyyyyyyyyyyy) => (
                                          <option
                                            key={countryyyyyyyyyyyyyy.phonecode}
                                            className="dropdown-options"
                                                // value={countryyyyyyyyyyyyyy.phonecode}
                                                value={countryyyyyyyyyyyyyy.id}
                                          >
                                            {countryyyyyyyyyyyyyy.name} (+
                                            {countryyyyyyyyyyyyyy.phonecode})
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="emergencyMobileNumber">
                                      ▸ Mobile Number
                                    </label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="emergencyMobileNumber"
                                      name="emergencyMobileNumber"
                                      placeholder="Enter Emergency Phone Number"
                                      style={{ height: "40px" }}
                                      value={emergencyMobileNumber}
                                      onChange={(e) =>
                                        setEmergencyMobileNumber(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>

                {/* {loading && (
                  <div className="d-flex justify-content-center mt-3">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )} */}

{loading && (
 <Loader />
      )}



                {/* Back and Save & Exit and Next Buttons */}

                <div
                  className="button-group text-center mb-1"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    {/* Back Button */}
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#ffcc01",
                        color: "#055837",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        border: "none",
                        borderRadius: "5px",
                        marginTop: "30px",
                        marginBottom: "20px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onClick={handleBack}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#055837";
                        e.target.style.color = "#ffcc01";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#ffcc01";
                        e.target.style.color = "#055837";
                      }}
                    >
                      Back
                    </button>

                    {/* Save & Exit Button */}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSaveAndExit}
                      style={{
                        backgroundColor: "#055837",
                        color: "#ffcc01",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        border: "none",
                        borderRadius: "5px",
                        marginTop: "30px",
                        marginBottom: "20px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#ffcc01";
                        e.target.style.color = "#055837";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#055837";
                        e.target.style.color = "#ffcc01";
                      }}
                    >
                      Save & Exit
                    </button>

                    {/* Next Button */}
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#ffcc01",
                        color: "#055837",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        border: "none",
                        borderRadius: "5px",
                        marginTop: "30px",
                        marginBottom: "20px",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                      onClick={handleNext}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#055837";
                        e.target.style.color = "#ffcc01";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#ffcc01";
                        e.target.style.color = "#055837";
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Leaving"
            message="Are you sure you want to leave this page? You don't want to continue this new application process right now? If you proceed, you'll be redirected to the dashboard."
          />

          {backButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackModal}
              onHide={handleConfirmationBackNo}
              onConfirm={handleConfirmationBackYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the Personal Details form page?"
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default ContactDetails;
