import React, { useState, useEffect } from "react";
import "./Final Page.css";
import { Button, Col, Row, Card, Spinner, Table } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import Heading from "../../Heading/Heading";
import NavigationButtons from "../../Navigation Buttons/Navigation Buttons";
import ConfirmationModal from "../../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
// import backgroundImage from "./bg_of_dashboard1.png";
import backgroundImage from "./loader.gif";

import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import {
  get_FinalPageApplications_API,
  triggerSendMailApi_API,
} from "../../../api";

const FinalPage = ({ onLogout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const pointerData = location.state?.pointerData;
  const [showOptions, setShowOptions] = useState({});

  const [logoutClicked, setLogoutClicked] = useState(false);
  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);

  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };

  const handleConfirmationLogoutYes = () => {
    localStorage.clear();
    onLogout();
    navigate("/");
    toast.success("Logged out successfully!");
  };

  const handleConfirmationLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  const [URL_Value, setURL_Value] = useState(null);

  const [NextStageClicked, setNextStageClicked] = useState(false);
  const [
    showConfirmationModalForNextStage,
    setShowConfirmationModalForNextStage,
  ] = useState(false);

  const handleNextStage = () => {
    setShowConfirmationModalForNextStage(true);
    setNextStageClicked(true);
  };

  const handleConfirmationNextStageYes = (url) => {
    triggerSendMailApi_Function(pointerData?.pointer_id, url);
    // navigate(url, { state: { pointerData: pointerData } });
  };

  const handleConfirmationNextStageNo = () => {
    setShowConfirmationModalForNextStage(false);
    setNextStageClicked(false);
    setURL_Value(null);
  };

  const handleBack = () => {
    navigate("/user/submitted_applications", {
      state: { pointerData },
    });
    toast.success("Navigated to Submitted Applications.");
  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  const [ApplicationDetails, setApplicationDetails] = useState({});

  const get_ViewApplication_Function = async (pointer_id) => {
    try {
      setLoading(true);
      const response = await get_FinalPageApplications_API(pointer_id);
      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.data &&
        response?.data?.response?.response === true
      ) {
        const Details = response?.data?.response?.data;
        setApplicationDetails(Details);

        if (Details?.navigate_to_view_application_page === true) {
          navigate("/user/final_page", {
            state: { pointerData },
          });
        }
      } else {
        setLoading(false);
        console.error(
          "Error fetching Details:",
          response?.data?.response?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching Details:", error);
    }
  };

  useEffect(() => {
    if (pointerData?.pointer_id) {
      get_ViewApplication_Function(pointerData.pointer_id);
    }
  }, [pointerData?.pointer_id]);

  const calculateCardHeight = () => {
    const baseHeight = "auto";

    if (Object.values(showOptions).some((v) => v)) {
      return `${baseHeight}`;
    } else {
      return "auto";
    }
  };

  const cardStyle = { height: calculateCardHeight() };

  const getButtonsValue = () => ApplicationDetails?.btns || [];
  const ButtonsByAPI = getButtonsValue();

  const renderButtons = (buttons = []) => {
    return buttons.map((button, index) => {
      const colSize = button?.size === "half" ? 6 : 12;
      return (
        <Col sm={colSize} className="p-3" key={index}>
          <a
            href={button?.btn_link}
            className="btn btn_green_yellow w-100"
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            {button?.btn_label} <i className="bi bi-download"></i>
          </a>
        </Col>
      );
    });
  };

  const s_3_new_below_data_getButtonsValue = () =>
    ApplicationDetails?.s_3_new_below_data_btns || [];
  const s_3_new_below_data_ButtonsByAPI = s_3_new_below_data_getButtonsValue();

  const s_3_new_below_data_renderButtons = (buttons = []) => {
    return buttons.map((button, index) => {
      const colSize = button?.size === "half" ? 6 : 12;
      return (
        <Col sm={colSize} className="p-3" key={index}>
          <a
            href={button?.btn_link}
            className="btn btn_green_yellow w-100"
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            {button?.btn_label} <i className="bi bi-download"></i>
          </a>
        </Col>
      );
    });
  };

  const getSubmittedDocsList = () => ApplicationDetails?.all_docs || {};
  const SubmittedDocsListByAPI = getSubmittedDocsList();

  const renderDocumentRows = (docs, category, stageChecker) => {
    // If docs array is empty, return null
    if (!docs || docs.length === 0) return null;

    return (
      <>
        {stageChecker === "Stage 2" && category && (
          <tr className="">
            <td colSpan={3} style={{ background: "#f0f0f0" }}>
              {/* <span>{category}</span> */}

              <span>
                <i className="fas fa-city" style={{ marginRight: "7px" }}></i>
                {category}
              </span>
            </td>
          </tr>
        )}

        {Array.isArray(docs)
          ? docs.map((doc, index) => (
              <tr key={index}>
                <td style={{ color: "#055837" }}>{index + 1}</td>

                <td style={{ display: "flex", alignItems: "center" }}>
                  <a
                    href={doc.full_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#055837", textDecoration: "none" }}
                    onMouseEnter={(e) =>
                      (e.target.style.textDecoration = "underline")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.textDecoration = "none")
                    }
                  >
                    {doc?.name}
                  </a>
                  <span style={{ color: "black", marginLeft: "4px" }}>
                    {doc?.is_additional === "yes" && "(Additional Info)"}
                  </span>
                </td>
              </tr>
            ))
          : Object.keys(docs).map((subCategory, idx) => (
              <React.Fragment key={subCategory}>
                {stageChecker === "stage_2" && (
                  <tr className="">
                    <td colSpan={3} style={{ background: "#f0f0f0" }}>
                      <span>
                        <i
                          className="fas fa-city"
                          style={{ marginRight: "7px" }}
                        ></i>
                        {subCategory}
                      </span>
                    </td>
                  </tr>
                )}

                {docs[subCategory].map((doc, index) => (
                  <tr key={`${subCategory}-${index}`}>
                    <td style={{ color: "#055837", marginLeft: "10px" }}>
                      {index + 1}
                    </td>
                    <td style={{ display: "flex", alignItems: "center" }}>
                      <a
                        href={doc.full_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#055837", textDecoration: "none" }}
                        onMouseEnter={(e) =>
                          (e.target.style.textDecoration = "underline")
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.textDecoration = "none")
                        }
                      >
                        {doc?.name}
                      </a>
                      <span style={{ color: "black", marginLeft: "4px" }}>
                        {doc?.is_additional === "yes" && "(Additional Info)"}
                      </span>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
      </>
    );
  };

  const renderStages = (allDocs) => {
    return Object.keys(allDocs).map((stage, index) => {
      // If documents for this stage are empty, skip rendering
      if (
        !allDocs[stage] ||
        (Array.isArray(allDocs[stage]) && allDocs[stage].length === 0) ||
        (typeof allDocs[stage] === "object" &&
          !Object.keys(allDocs[stage]).length)
      ) {
        return null;
      }

      return (
        <div className="dropdown-container mt-1" key={index}>
          <button
            className="toggle-container"
            onClick={() =>
              setShowOptions((prev) => ({ ...prev, [stage]: !prev[stage] }))
            }
          >
            <span className="toggle-heading">
              {stage} - Submitted Documents
            </span>
            <div className="toggle-icons">
              {showOptions[stage] ? <BsChevronUp /> : <BsChevronDown />}
            </div>
          </button>

          <div
            className={`options-container ${showOptions[stage] ? "show" : ""}`}
          >
            <table className="custom-table-for-submitted">
              <thead style={{ cursor: "default" }}>
                <tr>
                  <th>Sr.</th>
                  <th>Document Name</th>
                </tr>
              </thead>
              {Array.isArray(allDocs[stage]) ? (
                <tbody className="options-list">
                  {renderDocumentRows(allDocs[stage], null, stage)}
                </tbody>
              ) : (
                Object.keys(allDocs[stage]).map((category, idx) => (
                  <tbody key={idx} className="options-list">
                    {renderDocumentRows(
                      allDocs[stage][category],
                      category,
                      stage
                    )}
                  </tbody>
                ))
              )}
            </table>
          </div>
        </div>
      );
    });
  };

  const triggerSendMailApi_Function = async (pointer_id, url) => {
    try {
      setLoading(true);
      const response = await triggerSendMailApi_API(pointer_id);
      setLoading(false);
      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.data &&
        response?.data?.response?.response === true
      ) {
        console.log("mail sended");

        navigate(url, { state: { pointerData: pointerData } });

        setNextStageClicked(false);
        setShowConfirmationModalForNextStage(false);
        setURL_Value(null);
      } else {
        setLoading(false);
        console.error(
          "Error fetching Details:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Unknown error occurred."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching Details:", error);
    }
  };

  const getNextStageNavigation = () => ApplicationDetails?.next_btn || {} || [];

  const NextStageNavigationByAPI = getNextStageNavigation();

  const isNextStageAvailable =
    typeof NextStageNavigationByAPI === "object" &&
    NextStageNavigationByAPI !== null &&
    "label" in NextStageNavigationByAPI &&
    "url" in NextStageNavigationByAPI;

  const navigateToStage = (url) => {
    // triggerSendMailApi_Function(pointerData?.pointer_id , url);
    navigate(url, { state: { pointerData: pointerData } });
  };

  return (
    <>
      <Row>
        <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleBack}
            style={{
              color: "#055837",
              marginLeft: "25px",
              outline: "none",
              boxShadow: "none",
            }}
          >
            <BsArrowLeft className="me-0" />
            Back to Submitted Applications
          </Button>
        </Col>

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
        <Heading text="Application Details" />
      </Row>

      <div className="forgot-container-viewApplication">
        <Card className="shadow forgot-card-viewApplication" style={cardStyle}>
          <Card.Body>
            {loading && (
              <div className="d-flex justify-content-center mt-3">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            )}

            <Row>
              <Col md={8}>
                <div className="table-container">
                  <Table className="custom-tableData">
                    <tbody>
                      <tr>
                        <td
                          className="bold-black"
                          style={{ fontWeight: "bold" }}
                        >
                          Portal Reference Number
                        </td>
                        <td className="bold">
                          {pointerData?.portal_refrance_no}
                        </td>
                      </tr>
                      <tr>
                        <td
                          className="bold-black"
                          style={{ fontWeight: "bold" }}
                        >
                          Application No.
                        </td>
                        <td className="normal-greeenn-text">
                          {ApplicationDetails?.application_details?.unique_id}
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="bold-black"
                          style={{ fontWeight: "bold" }}
                        >
                          Applicant's Name
                        </td>
                        <td className="normal-greeenn-text">
                          {ApplicationDetails?.application_details?.name}
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="bold-black"
                          style={{ fontWeight: "bold" }}
                        >
                          Occupation
                        </td>
                        <td className="normal-greeenn-text">
                          {ApplicationDetails?.application_details?.occupation}
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="bold-black"
                          style={{ fontWeight: "bold" }}
                        >
                          Pathway
                        </td>
                        <td className="normal-greeenn-text">
                          {ApplicationDetails?.application_details?.pathway}
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="bold-black"
                          style={{ fontWeight: "bold" }}
                        >
                          Program
                        </td>
                        <td className="normal-greeenn-text">
                          {ApplicationDetails?.application_details?.program}
                        </td>
                      </tr>

                      <tr>
                        <td
                          className="bold-black"
                          style={{ fontWeight: "bold" }}
                        >
                          Date Submitted
                        </td>
                        <td className="normal-greeenn-text">
                          {
                            ApplicationDetails?.application_details
                              ?.submitted_date
                          }
                        </td>
                      </tr>

                      {ApplicationDetails?.application_details
                        ?.approved_date && (
                        <tr>
                          <td
                            className="bold-black"
                            style={{ fontWeight: "bold" }}
                          >
                            Date Approved
                          </td>
                          <td className="normal-greeenn-text">
                            {
                              ApplicationDetails?.application_details
                                ?.approved_date
                            }
                          </td>
                        </tr>
                      )}

                      {ApplicationDetails?.application_details
                        ?.declined_date && (
                        <tr>
                          <td
                            className="bold-black"
                            style={{ fontWeight: "bold" }}
                          >
                            Date Declined
                          </td>
                          <td className="normal-greeenn-text">
                            {
                              ApplicationDetails?.application_details
                                ?.declined_date
                            }
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td
                          className="bold-black"
                          style={{ fontWeight: "bold" }}
                        >
                          Status
                        </td>
                        <td className="bold" style={{ fontWeight: "bold" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {ApplicationDetails?.application_details?.status}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

                <div className="table-container">
                  <Row>{renderButtons(ButtonsByAPI)}</Row>

                  {isNextStageAvailable && (
                    <Row>
                      <Col sm={12} className="p-3">
                        <button
                          className="btn btn_yellow_green w-100"
                          onClick={() => {
                            if (
                              NextStageNavigationByAPI?.label ===
                              "Apply for Reassessment"
                            ) {
                              setURL_Value(NextStageNavigationByAPI?.url);
                              handleNextStage();
                            } else {
                              navigateToStage(NextStageNavigationByAPI?.url);
                            }
                          }}

                          // onClick={() => {
                          //   setURL_Value(NextStageNavigationByAPI?.url);
                          //   handleNextStage();
                          // }}
                        >
                          {NextStageNavigationByAPI?.label}{" "}
                          <i className="bi bi-arrow-right"></i>
                        </button>
                      </Col>
                    </Row>
                  )}

                  <div>
                    {ApplicationDetails?.application_details
                      ?.s_3_new_below_data_status && (
                      <div className="mt-3 mb-1">
                        <label
                          htmlFor="inputName4"
                          className="form-label text-center w-100 py-2"
                          style={{
                            backgroundColor: "#ffc107",
                            color: "#055837",
                            fontSize: "17px",
                            borderRadius: "5px",
                            padding: "7px",
                            fontWeight: "bold",
                          }}
                        >
                          Reassessment
                        </label>
                      </div>
                    )}

                    <div className="table-container">
                      <Table className="custom-tableData">
                        <tbody>
                          {ApplicationDetails?.application_details
                            ?.s_3_new_below_data_submitted_date && (
                            <>
                              <tr>
                                <td
                                  className="bold-black"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Date Submitted
                                </td>
                                <td className="normal-greeenn-text">
                                  {
                                    ApplicationDetails?.application_details
                                      ?.s_3_new_below_data_submitted_date
                                  }
                                </td>
                              </tr>
                            </>
                          )}

                          {ApplicationDetails?.application_details
                            ?.s_3_new_below_data_declined_date && (
                            <tr>
                              <td
                                className="bold-black"
                                style={{ fontWeight: "bold" }}
                              >
                                Date Declined
                              </td>
                              <td className="normal-greeenn-text">
                                {
                                  ApplicationDetails?.application_details
                                    ?.s_3_new_below_data_declined_date
                                }
                              </td>
                            </tr>
                          )}

                          {ApplicationDetails?.application_details
                            ?.s_3_new_below_data_approved_date && (
                            <tr>
                              <td
                                className="bold-black"
                                style={{ fontWeight: "bold" }}
                              >
                                Date Approved
                              </td>
                              <td className="normal-greeenn-text">
                                {
                                  ApplicationDetails?.application_details
                                    ?.s_3_new_below_data_approved_date
                                }
                              </td>
                            </tr>
                          )}

                          {ApplicationDetails?.application_details
                            ?.s_3_new_below_data_status && (
                            <tr>
                              <td
                                className="bold-black"
                                style={{ fontWeight: "bold" }}
                              >
                                Status
                              </td>
                              <td
                                className="bold"
                                style={{ fontWeight: "bold" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {
                                    ApplicationDetails?.application_details
                                      ?.s_3_new_below_data_status
                                  }
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>

                    {s_3_new_below_data_ButtonsByAPI &&
                      ApplicationDetails?.s_3_new_below_data_btns && (
                        <Row>
                          {s_3_new_below_data_renderButtons(
                            s_3_new_below_data_ButtonsByAPI
                          )}
                        </Row>
                      )}
                  </div>
                </div>
              </Col>

              <Col md={4} xs={12}>
                <div className="image-and-dropdown-container">
                  {/* <img
                    src={
                      ApplicationDetails?.application_details?.profile_url ||
                      backgroundImage
                    }
                    alt="User Profile"
                    className="img-fluid"
                    style={{ height: "250px", border: "2px solid #ffcc01" }}
                  />
                   */}

                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "100%" }}
                  >
                    <div>
                      <img
                        src={
                          ApplicationDetails?.application_details
                            ?.profile_url || backgroundImage
                        }
                        alt="User Profile"
                        className="img-fluid"
                        style={{
                          height: "250px",
                          width: "250px",
                          border: "2px solid #ffcc01",
                          objectFit: "cover",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                  </div>

                  <div className="mt-2 mb-2">
                    {renderStages(SubmittedDocsListByAPI)}
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>

      {logoutClicked && (
        <ConfirmationModal
          show={showConfirmationModalForLogout}
          onHide={handleConfirmationLogoutNo}
          onConfirm={handleConfirmationLogoutYes}
          title="Confirm Exit"
          message="Are you sure you want to log out?"
        />
      )}

      {NextStageClicked && URL_Value && showConfirmationModalForNextStage && (
        <ConfirmationModal
          show={showConfirmationModalForNextStage}
          onHide={handleConfirmationNextStageNo}
          onConfirm={() => handleConfirmationNextStageYes(URL_Value)}
          title="Confirm!"
          message="Are you sure you want to initiate the reassessment ?"
        />
      )}
    </>
  );
};

export default FinalPage;
