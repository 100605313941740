import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Heading from "../Heading/Heading";
import { LoginButton, AccountLink } from "../Common Modules/Common Modules";
import "./Login.css";
import { Button, Card, Form, Modal } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkFillAllTheDetailsDataAPI, LoginAPI } from "../../api";
import CaptchaComponent from "../Captcha Component/Captcha Component";
import { encryptData } from "../CRYPTO/crypto";
import Loader from "../Loader/Loader";
import { useLocation } from "react-router-dom";
import axios from "axios";


const Login = ({ onLogin, onLogout }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const location = useLocation();

  const [VarifiedSuccess, setVarifiedSuccess] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const account_verified = queryParams.get('account_verified');
    setVarifiedSuccess(account_verified);
  }, [location.search]);

  const closeVerifiedModal = () => {
    setVarifiedSuccess(false);
  };

  const navigate = useNavigate();

  const handleCaptchaVerification = (isVerified) => {
    setIsCaptchaVerified(isVerified);
  };

  const handleLogin = () => {
    setIsLoading(true);
    handleSubmit();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    navigate("/user/forgot_password");
  };

  const handleCheckForDetails = async (token) => {
    // setIsLoading(true);
    try {
      
      const response = await checkFillAllTheDetailsDataAPI(token);

      // setIsLoading(true);
      if (
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.success_msg === "Proceed to Dashboard" &&
        response?.data?.response?.response === true
      ) {

        // setIsLoading(false);

        // toast.success(
        //   `Login successful. Welcome, ${response?.data?.response?.data?.user_name} !`
        // );

        localStorage.setItem("userId", response?.data?.response?.data?.userId);

        localStorage.setItem("userName", response?.data?.response?.data?.user_name);


        localStorage.setItem("profileDetailsFilled", true);
        navigate("/user/dashboard");
        onLogin && onLogin();
        setIsLoading(false);


      } else if (
        response.status === 200 &&
        response.data === "Missing JWT token"
      ) {
        // setIsLoading(true);
        handleSubmit();
        // setIsLoading(true);
      } else {
        // setIsLoading(false);

        localStorage.setItem("userId", response?.data?.response?.data?.userId);
        localStorage.setItem("profileDetailsFilled", false);
        localStorage.setItem("userName", response?.data?.response?.data?.user_name);

        // toast.warning(
        //   `Complete your profile firstly, ${response?.data?.response?.data?.user_name} !`
        // );


        navigate("/user/complete_profile");
        // setIsLoading(false);

      }
    } catch (error) {
      console.error("Error checking for details:", error);
      toast.error(
        "An error occurred while checking for details. Please try again."
      );

    }
  };

  const handleSubmit = async () => {
   

      if (!email || !validateEmail(email)) {
        setEmailError(true);
        toast.error("Please enter a valid email address.");
        document.getElementById("formBasicEmail").focus();
        return setIsLoading(false);
      }
      if (!password) {
        toast.error("Please enter your password.");
        document.getElementById("formBasicPassword").focus();
        return setIsLoading(false);
      }
      if (!isCaptchaVerified) {
        toast.error("Captcha Verification code does not match.");
        return setIsLoading(false);
      }






    setIsLoading(true);


    try {

      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
  
      setIsLoading(true);
  
      // Direct Axios call for the login API
      const loginResponse = await axios.post(
        process.env.REACT_APP_AQATO_AGENT_APPLICANT_PORTAL_BASE_API_URL + "/user/user_login_check",
        formData
      );

  
      const responseData = loginResponse?.data?.response;

      setIsLoading(false);

  
      if (responseData && responseData?.response === true) {
        const token = responseData.token;
        const encryptedToken = encryptData(token);
  
        // Clear localStorage and set new values
        localStorage.clear();

        localStorage.setItem("account_type", responseData?.data?.account_type);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("encryptedToken", encryptedToken);
        localStorage.setItem("userId", responseData?.data?.user_id);
  





        if (responseData?.data?.navigator_to_dashboard === true) {
          toast.success("Login successful !");
          navigate("/user/dashboard");
      } else {
          toast.warning("Complete your profile first !");
          navigate("/user/complete_profile");
      }
      






              // Handle details check and loading state
              handleCheckForDetails(token);


      } else {

        if (responseData?.error_msg === "Invalid Username Or Password") {
          toast.error("Invalid email or password. Please try again.");
        } else if (responseData?.error_msg) {
          toast.error(responseData.error_msg);
        }

      }
    } catch (error) {
      console.error("Login failed:", error);
      toast.error("An error occurred during login. Please try again.");
    } finally {
      setIsLoading(false); // Ensure loading is stopped in any case
    

    }








  };

  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    setEmailError(!validateEmail(emailInput));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleLogin();
    }
  };








  return (
    <>
      <Heading text="Welcome to the Skills Assessment Online Portal" />

      <div className="login-container">
        <Card className="shadow login-card shadow">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Applicant/Agent Login</b>
          </div>
          <Card.Body>
            {isLoading && <Loader />}

            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
              onKeyDown={handleKeyDown}
            >
              <div className="login-card-input">
                <Form.Group controlId="formBasicEmail">
                  <div className="input-group" style={{ height: "40px" }}>
                    <span
                      className="input-group-text"
                      style={{ backgroundColor: "#e9ecef", height: "100%" }}
                    >
                      <i
                        className="bi bi-envelope-fill"
                        style={{
                          fontSize: "1.5rem",
                          color: "black",
                          fontWeight: "bold",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      ></i>
                    </span>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={handleEmailChange}
                      style={{ height: "100%" }}
                    />
                  </div>

                  {emailError && (
                    <div className="error-message">
                      Invalid Email Address. Please enter a valid email. *{" "}
                    </div>
                  )}
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <div className="input-group" style={{ height: "40px" }}>
                    <span
                      className="input-group-text"
                      style={{ backgroundColor: "#e9ecef", height: "100%" }}
                    >
                      <i
                        className="bi bi-key-fill"
                        style={{
                          fontSize: "1.5rem",
                          color: "black",
                          fontWeight: "bold",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      ></i>
                    </span>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ height: "100%" }}
                    />

                    <span
                      className="input-group-text"
                      style={{ backgroundColor: "#e9ecef", cursor: "pointer" }}
                      onClick={togglePasswordVisibility}
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        style={{ fontSize: "1rem", color: "black" }}
                      />
                    </span>
                  </div>
                </Form.Group>

                <div className="forgot-password-container">
                  <a
                    className="forgot-password-link"
                    onClick={handleForgotPassword}
                  >
                    Forgot Password?
                  </a>
                </div>
              </div>

              <CaptchaComponent
                onCaptchaVerification={handleCaptchaVerification}
              />
            </Form>
          </Card.Body>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <LoginButton
              onClick={handleLogin}
              buttonText={
                isLoading ? (
                  <div
                    className="spinner-border text-light"
                    role="status"
                    style={{ width: "1rem", height: "1rem", color: "#055837" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Login"
                )
              }
              icon={true}
              disabled={isLoading}
            />
          </div>

          <div
            style={{
              textAlign: "center",
            }}
          >
            <div className="centered-content">
              <AccountLink
                linkText="Create an account"
                linkUrl="/user/create_new_account"
                promptText="Don't have an account?"
                onClick={() => navigate("/user/create_new_account")}
              />
            </div>
          </div>
        </Card>
      </div>

      {/* Modal for Verified Success */}
      <Modal
        show={VarifiedSuccess}
        onHide={closeVerifiedModal}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#055837" }}>
            Account Verified !
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your account has been successfully verified. Please log in to
          continue.
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="light"
            onClick={closeVerifiedModal}
            // className="w-50"
            style={{
              maxWidth: "100px",
              backgroundColor: "#ffcc01",
              color: "#055837",
              transition: "transform 0.2s",
              fontSize: "17px",
            }}
            onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
            onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>



    </>
  );
};

export default Login;
