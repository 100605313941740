import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Heading from "../Heading/Heading";
import "./Reset Password.css";
import { Card, Form } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Spinner } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { updatePasswordAPI } from "../../api";
import CaptchaComponent from "../Captcha Component/Captcha Component";
import { useLocation } from "react-router-dom";
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";
import Loader from "../Loader/Loader";

const ResetPassworddd = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const userID = location.state?.userID;



  const [Email, setEmail] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    setEmail(email);
  }, [location.search]);




  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,20}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must be 8-20 characters, including at least one lowercase letter, one uppercase letter, one digit, and one special character. *"
      );
      return false;
    }
    setPasswordError("");
    return true;
  };

  const handleSubmitUpdatePassword = async () => {
    // if (oldPassword.trim() === "") {
    //   setOldPasswordError("Please enter your old password. *");
    //   toast.error("Please enter your old password.");
    //   return;
    // }
    if (!validatePassword(newPassword)) {
      toast.error(
        "Password must be 8-20 characters, including at least one lowercase letter, one uppercase letter, one digit, and one special character."
      );
      return;
    }
    if (confirmNewPassword.trim() === "") {
      setConfirmPasswordError("Please confirm your new password. *");
      toast.error("Please confirm your new password.");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setConfirmPasswordError("Passwords do not match. *");
      toast.error("Passwords do not match.");
      return;
    }

    if (!isCaptchaVerified) {
      toast.error("Verification code does not match.");
      return;
    }


    const formData = new FormData();


    // formData.append("user_id", userID);
    formData.append("email", Email);
    // formData.append("old_pass", oldPassword);
    // formData.append("new_pass", newPassword);
    formData.append("new_pass", confirmNewPassword);

    try {
      setLoading(true);

      const response = await updatePasswordAPI(formData);

      setLoading(false);

      if (response?.data?.response?.response === true) {
        // Reset errors
        setOldPasswordError("");
        setPasswordError("");
        setConfirmPasswordError("");

        // Show success toast message
        toast.success("Your password has been successfully updated. Please log in again to continue.");


        // Reset fields
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");

        navigate("/");






      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Profile Details"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error saving Profile Details:", error);
      toast.error("Failed to save Profile Details");
    }
  };

  const togglePasswordVisibility = (passwordType) => {
    switch (passwordType) {
      case "old":
        setShowOldPassword(!showOldPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmNewPassword(!showConfirmNewPassword);
        break;
      default:
        break;
    }
  };

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const handleCaptchaVerification = (isVerified) => {
    setIsCaptchaVerified(isVerified);
  };

  const [showConfirmationBackModal, setShowConfirmationBackModal] =
    useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const handleConfirmationBackNo = () => {
    setShowConfirmationBackModal(false);
  };
  const handleConfirmationBackYes = () => {
    navigate("/");
    toast.success("Navigated to homepage.");
    setShowConfirmationBackModal(false);
  };

  const handleBack = () => {
    setBackButtonClicked(true);
    setShowConfirmationBackModal(true);
  };
  return (
    <>
      <Heading text="Welcome to the Skills Assessment Online Portal" />



      {loading && (
 <Loader />
      )}

      <div className="forgot-container-like-login">
        <Card className="shadow forgot-card-like-login">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Reset Password</b>
          </div>
          <Card.Body>
            <Form>


              {/* <Form.Group controlId="formOldPassword" className="mb-3">
                <Form.Label className="w-100 mb-1">
                  Old Password <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className="input-group">
                  <Form.Control
                    type={showOldPassword ? "text" : "password"}
                    placeholder="Enter old password"
                    value={oldPassword}
                    onChange={(e) => {
                      setOldPassword(e.target.value);
                      setOldPasswordError("");
                    }}
                  />
                  <Button
                    variant="outline-secondary"
                    type="button"
                    onClick={() => togglePasswordVisibility("old")}
                  >
                    {showOldPassword ? (
                      <FaEyeSlash size={20} />
                    ) : (
                      <FaEye size={20} />
                    )}
                  </Button>
                </div>
                {oldPasswordError && (
                  <Form.Text className="text-danger">
                    {oldPasswordError}
                  </Form.Text>
                )}
              </Form.Group> */}









              <Form.Group controlId="formNewPassword" className="mb-3">
                <Form.Label className="w-100 mb-1">
                  New Password <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className="input-group">
                  <Form.Control
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => {
                      const newPasswordValue = e.target.value;
                      setNewPassword(newPasswordValue);
                      // Validate new password
                      if (!validatePassword(newPasswordValue)) {
                        setPasswordError(
                          "Password must be 8-20 characters, including at least one lowercase letter, one uppercase letter, one digit, and one special character."
                        );
                      } else {
                        setPasswordError("");
                      }
                    }}
                  />
                  <Button
                    variant="outline-secondary"
                    type="button"
                    onClick={() => togglePasswordVisibility("new")}
                  >
                    {showNewPassword ? (
                      <FaEyeSlash size={20} />
                    ) : (
                      <FaEye size={20} />
                    )}
                  </Button>
                </div>
                {passwordError && (
                  <Form.Text className="text-danger">{passwordError}</Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="formConfirmNewPassword" className="mb-3">
                <Form.Label className="w-100 mb-1">
                  Confirm New Password <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className="input-group">
                  <Form.Control
                    type={showConfirmNewPassword ? "text" : "password"}
                    placeholder="Confirm new password"
                    value={confirmNewPassword}
                    onChange={(e) => {
                      const confirmNewPasswordValue = e.target.value;
                      setConfirmNewPassword(confirmNewPasswordValue);
                      // Validate confirm new password
                      if (confirmNewPasswordValue !== newPassword) {
                        setConfirmPasswordError("Passwords do not match. *");
                      } else {
                        setConfirmPasswordError("");
                      }
                    }}
                  />
                  <Button
                    variant="outline-secondary"
                    type="button"
                    onClick={() => togglePasswordVisibility("confirm")}
                  >
                    {showConfirmNewPassword ? (
                      <FaEyeSlash size={20} />
                    ) : (
                      <FaEye size={20} />
                    )}
                  </Button>
                </div>
                {confirmPasswordError && (
                  <Form.Text className="text-danger">
                    {confirmPasswordError}
                  </Form.Text>
                )}
              </Form.Group>

              <CaptchaComponent
                onCaptchaVerification={handleCaptchaVerification}
              />
            </Form>
          </Card.Body>

          {/* {loading && (
            <div className="d-flex justify-content-center mt-3">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )} */}

          {backButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackModal}
              onHide={handleConfirmationBackNo}
              onConfirm={handleConfirmationBackYes}
              title="Confirm Cancel"
              message="Are you sure you want to go back to the home page?"
            />
          )}

          {/* Save&Exit and Next Buttons */}
          <div
            className="button-group text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            {/* Back and Save & Exit and Next Buttons */}
            <div
              className="button-group text-center mb-1"
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              {/* Back Button */}
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleBack}
                style={{
                  backgroundColor: "#055837",
                  color: "#ffcc01",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  border: "none",
                  borderRadius: "5px",
                  marginTop: "30px",
                  marginBottom: "20px",
                  transition: "background-color 0.3s, color 0.3s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#ffcc01";
                  e.target.style.color = "#055837";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#055837";
                  e.target.style.color = "#ffcc01";
                }}
              >
                Cancel
              </button>

              {/* Next Button */}
              <button
                type="button"
                className="btn btn-primary"
                style={{
                  backgroundColor: "#ffcc01",
                  color: "#055837",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  border: "none",
                  borderRadius: "5px",
                  marginTop: "30px",
                  marginBottom: "20px",
                  transition: "background-color 0.3s, color 0.3s",
                }}
                onClick={handleSubmitUpdatePassword}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#055837";
                  e.target.style.color = "#ffcc01";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#ffcc01";
                  e.target.style.color = "#055837";
                }}
              >
                Update
              </button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default ResetPassworddd;
