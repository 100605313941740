import React, { useState, useEffect } from "react";
import "./Upload Documents.css";
import ConfirmationModal from "../../../Confirmation Modal/ConfirmationModal";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, Form, Button, Spinner } from "react-bootstrap";
import { XCircleFill, CheckCircleFill } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";

import {
  getuploadedDocumentsList_stage1_API,
  FinalSubmission_Of_stage1_API,
  stage1_get_assessments_docs_names,
} from "../../../../api";

import UploadModal from "./Upload Modal/Upload Modal";
import Others from "./Others/Others";
import Loader from "../../../Loader/Loader";

const UploadDocuments = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [termsChecked, setTermsChecked] = useState(false);
  const [currentDoc, setCurrentDoc] = useState(null);
  const [Stage_1_DocsList, setStage_1_DocsList] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);

  useEffect(() => {
    const fetchDocumentsListFunction = async () => {
      const formData = new FormData();
      formData.append("pointer_id", pointerData?.pointer_id);
      formData.append("stage", "stage_1");

      try {
        const response = await stage1_get_assessments_docs_names(formData);
        if (
          response?.data?.response &&
          response?.data?.response?.response === true &&
          response?.data?.response?.data
        ) {
          setStage_1_DocsList(response?.data?.response?.data);
        } else {
          console.error(
            "Error fetching assessment documents:",
            response?.data?.response?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching assessment documents:", error);
      }
    };

    if (pointerData?.pointer_id) {
      fetchDocumentsListFunction();
    }
  }, [pointerData?.pointer_id]);

  const getUploadedDocsList = async () => {
    try {
      setLoading(true);
      const response = await getuploadedDocumentsList_stage1_API(
        pointerData?.pointer_id
      );
      setLoading(false);

      if (response?.data?.response) {
        // Ensure all status values are treated as numbers
        const uploadedStatus = response.data.response.map((doc) => ({
          ...doc,
          status: Number(doc.status),
        }));
        setUploadedDocs(uploadedStatus);
      } else {
        console.error(
          "Error fetching data:",
          response?.data?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (pointerData?.pointer_id) {
      getUploadedDocsList();
    }
  }, [pointerData?.pointer_id]);

  const handleOpenPopupModal = (doc) => {
    setCurrentDoc(doc);
    setShowUploadModal(true);
  };

  const handleClosePopupModal = () => {
    setShowUploadModal(false);
    setCurrentDoc(null);
  };

  const isDocUploaded = (docId) => {
    docId = Number(docId);

    const doc = uploadedDocs.find((item) => {
      const itemId = Number(item.id);
      return itemId === docId;
    });

    return doc && Number(doc.status) === 1;
  };

  const renderUploadModal = (doc) => {
    const allowedTypes = JSON.parse(doc?.allowed_type || "[]");

    if (doc?.is_multiple === "0") {
      return (
        <UploadModal
          show={showUploadModal && currentDoc?.id === doc?.id}
          onHide={handleClosePopupModal}
          pointerData={pointerData}
          getUploadedDocsList={getUploadedDocsList}
          ModalHeading={doc?.document_name}
          ModalID={doc?.id}
          allowedTypes={allowedTypes}
          isRequired={doc.is_required}
        />
      );
    } else if (doc?.is_multiple === "1") {
      return (
        <Others
          show={showUploadModal && currentDoc?.id === doc?.id}
          onHide={handleClosePopupModal}
          pointerData={pointerData}
          getUploadedDocsList={getUploadedDocsList}
          ModalHeading={doc?.document_name}
          ModalID={doc?.id}
          isRequired={doc.is_required}
          allowedTypes={allowedTypes}
        />
      );
    }
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleBack = () => {
    navigate("/user/create_new_application/stage_1/application_declaration", {
      state: { pointerData },
    });
    toast.success("Navigated to Applicant Declaration form page.");
  };

  const [showConfirmationSubmitModal, setShowConfirmationSubmitModal] =
    useState(false);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const handleConfirmationSubmitNo = () => {
    setShowConfirmationSubmitModal(false);
  };

  const handleConfirmationSubmitYes = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await FinalSubmission_Of_stage1_API(
        pointerData?.pointer_id
      );
   

      if (response?.data?.response?.response === true) {

        toast.success("Stage 1 submitted successfully.");
        navigate("/user/view_application", { state: { pointerData } });
        setShowConfirmationSubmitModal(false);

        setLoading(false);


      } else {
        toast.error(
          response?.data?.response?.error_msg ||
            "Please Upload All The Required Documents"
        );
        setShowConfirmationSubmitModal(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setShowConfirmationSubmitModal(false);
      console.error("Error in saving stage 1:", error);
      toast.error("Failed to save stage 1");
    }
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationYes = () => {
    navigate("/user/dashboard");
    setShowConfirmationModal(false);
    toast.success("Documents Saved successfully.");
  };

  const handleNext = async (e) => {
    e.preventDefault();

    if (!termsChecked) {
      toast.error("Please agree to the terms and conditions.");
      document.getElementById("termsAndConditions").focus();

      return;
    }

    setSubmitButtonClicked(true);
    setShowConfirmationSubmitModal(true);
  };

  const handleSaveAndExit = async (e) => {
    e.preventDefault();
    setShowConfirmationModal(true);
  };

  return (
    <>
      <div className="forgot-container-upload">
        <Card className="shadow forgot-card-upload shadow">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Upload Documents</b>
          </div>
          <Card.Body>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 d-flex justify-content-end">
                <div className="mb-3 mt-1">
                  Portal Reference No. :{" "}
                  {/* <b style={{ color: "#055837" }}>24AQ063</b> */}
                  <b style={{ color: "#055837" }}>
                    {pointerData?.portal_refrance_no}
                  </b>
                </div>
              </div>
            </div>

            <Form>
              <div
                className="rounded p-2 mb-3"
                style={{ background: "#ebebeb" }}
              >
                <h4 className="mb-3">Instructions for upload</h4>
                <ul className="list-unstyled">
                  <li className="mb-1" style={{ color: "#000" }}>
                    &#8226; The maximum size of a file must not exceed 5 MB.
                  </li>
                  <li className="mb-1" style={{ color: "#000" }}>
                    &#8226; All uploaded documents need to be high quality color
                    scans of the original documents.
                  </li>
                  <li className="mb-1" style={{ color: "#000" }}>
                    &#8226; Make sure you upload your relevant documents under
                    the relevant category.
                  </li>
                  <li className="mb-1" style={{ color: "#000" }}>
                    &#8226; Click on the document category to upload the
                    relevant document(s).
                  </li>
                </ul>
              </div>

              {loading ? (
                // <div className="d-flex justify-content-center mt-3">
                //   <Spinner animation="border" role="status">
                //     <span className="sr-only">Loading...</span>
                //   </Spinner>
                // </div>

                <Loader />
              ) : (
                Stage_1_DocsList.map((doc) =>
                  isDocUploaded(doc.id) ? (
                    <div
                      key={doc.id}
                      className="docUploaded"
                      style={{
                        marginBottom: "15px",
                        border: "1px solid #055837",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="shadow p-4">
                        <div className="accordion-header-wrapper">
                          <button
                            className="accordion-button"
                            type="button"
                            onClick={() => handleOpenPopupModal(doc)}
                          >
                            <p className="accordion-header" id="headingOne">
                              <div className="d-flex align-items-center">
                                <span style={{ marginRight: "10px" }}>
                                  <CheckCircleFill />
                                </span>
                                <span>
                                  {doc.document_name}{" "}
                                  {doc.is_required === "1" && (
                                    <span style={{ color: "red" }}>*</span>
                                  )}{" "}
                                  {/* - Uploaded Successfully! */}
                                </span>
                              </div>
                            </p>
                          </button>
                          <span style={{ marginRight: "20px" }}>✔️</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={doc?.id}
                      className="bg-white shadow p-4 docNotUploaded"
                      style={{
                        marginBottom: "15px",
                        border: "1px solid #ffcc01",
                        borderRadius: "5px",
                      }}
                    >
                      <div className="accordion-header-wrapper">
                        <button
                          className="accordion-button"
                          type="button"
                          onClick={() => handleOpenPopupModal(doc)}
                        >
                          <p className="accordion-header" id="headingOne">
                            <div className="d-flex align-items-center">
                              <span className="bullet-point"></span>
                              <span>
                                {doc.document_name}{" "}
                                {doc.is_required === "1" && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </span>
                            </div>
                          </p>
                        </button>
                        <span style={{ marginRight: "20px" }}>⟫</span>
                      </div>
                    </div>
                  )
                )
              )}

              {/* Terms and Conditions */}
              <div
                className="form-group form-check mb-1 mt-2"
                style={{ marginTop: "30px" }}
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="termsAndConditions"
                  name="termsAndConditions"
                  checked={termsChecked}
                  onChange={() => setTermsChecked(!termsChecked)}
                  style={{
                    transform: "scale(1.0)",
                    border: "1px solid #055837",
                    color: "#055837",
                    backgroundColor: termsChecked ? "#055837" : "",
                    cursor: "pointer",
                  }}
                  aria-describedby="termsError"
                />
                <label
                  className="form-check-label"
                  htmlFor="termsAndConditions"
                >
                  I understand & agree that once I submit the documents, I will
                  not be able to remove or change these documents.
                </label>
                {!termsChecked && (
                  <div id="termsError" className="invalid-feedback">
                    * Please agree to the terms and conditions!
                  </div>
                )}
              </div>
              {/* Back and Save & Exit and Next Buttons */}
              <div
                className="button-group text-center mb-1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {/* Back Button */}
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#ffcc01",
                    color: "#055837",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    border: "none",
                    borderRadius: "5px",
                    marginTop: "30px",
                    marginBottom: "20px",
                    transition: "background-color 0.3s, color 0.3s",
                  }}
                  onClick={handleBack}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#055837";
                    e.target.style.color = "#ffcc01";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#ffcc01";
                    e.target.style.color = "#055837";
                  }}
                >
                  Back
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSaveAndExit}
                  style={{
                    backgroundColor: "#055837",
                    color: "#ffcc01",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    border: "none",
                    borderRadius: "5px",
                    marginTop: "30px",
                    marginBottom: "20px",
                    transition: "background-color 0.3s, color 0.3s",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#ffcc01";
                    e.target.style.color = "#055837";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#055837";
                    e.target.style.color = "#ffcc01";
                  }}
                >
                  Save & Exit
                </button>

                {/* Next Button */}
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#ffcc01",
                    color: "#055837",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    border: "none",
                    borderRadius: "5px",
                    marginTop: "30px",
                    marginBottom: "20px",
                    transition: "background-color 0.3s, color 0.3s",
                  }}
                  onClick={handleNext}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#055837";
                    e.target.style.color = "#ffcc01";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#ffcc01";
                    e.target.style.color = "#055837";
                  }}
                >
                  Submit Application
                </button>
              </div>
            </Form>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Leaving"
            message="Are you sure you want to leave this page? You don't want to continue this new application process right now? If you proceed, you'll be redirected to the dashboard."
          />

          {submitButtonClicked && (
            <ConfirmationModal
              show={showConfirmationSubmitModal}
              onHide={handleConfirmationSubmitNo}
              onConfirm={handleConfirmationSubmitYes}
              title="Confirm Submit Stage 1"
              message="Are you sure you want to submit the application? You will not be able to remove or change these documents after submission?"
            />
          )}

          {Stage_1_DocsList.map((doc) => (
            <React.Fragment key={doc?.id}>
              {renderUploadModal(doc)}
            </React.Fragment>
          ))}
        </Card>
      </div>
    </>
  );
};

export default UploadDocuments;
