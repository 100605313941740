import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Heading from "../Heading/Heading";
import "./Update Profile.css";
import { Card, Form } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Button, Dropdown, Spinner } from "react-bootstrap";
import { BsFillPersonFill, BsBoxArrowRight, BsArrowLeft } from "react-icons/bs";
import PasswordResetModal from "./Password Reset Modal";
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";
import {
  updateProfileAPI,
  getAllCountryDataAPI,
  getUserDataAPI,
} from "../../api";
import NavigationButtons from "../Navigation Buttons/Navigation Buttons";
import Loader from "../Loader/Loader";

const UpdateProfile = ({ onLogout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const userID = localStorage.getItem("userId");

  
  const [countriesData, setCountriesData] = useState([]);

  useEffect(() => {
    // Fetch countries when component mounts
    const fetchCountries = async () => {
      try {
        const response = await getAllCountryDataAPI();
        if (response.data?.response && response.data?.response?.data) {
          setCountriesData(response.data.response.data);
        } else {
          console.error(
            "Error fetching countries:",
            response.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  // const [accountType, setAccountType] = useState("Applicant");
  const [accountType, setAccountType] = useState("");
  const [maraAgent, setMaraAgent] = useState("");

  const [termsChecked, setTermsChecked] = useState(true);
  const [marn, setMarn] = useState("");
  const [email, setEmail] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [businessName, setBusinessName] = useState("");

  const [unitFlatNumber, setUnitFlatNumber] = useState("");
  const [streatLotNumber, setStreatLotNumber] = useState("");
  const [streatName, setStreatName] = useState("");
  const [suburbCity, setSuburbCity] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [postCode, setPostCode] = useState("");
  const [mobileCountryCode, setMobileCountryCode] = useState("13");
  const [mobileNumber, setMobileNumber] = useState("");
  const [telephoneCountryCode, setTelephoneCountryCode] = useState("13");
  const [telephoneAreaCode, setTelephoneAreaCode] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");

  const [unitFlatNumberPostal, setUnitFlatNumberPostal] = useState("");
  const [streatLotNumberPostal, setStreatLotNumberPostal] = useState("");
  const [streatNamePostal, setStreatNamePostal] = useState("");
  const [suburbCityPostal, setSuburbCityPostal] = useState("");
  const [stateProvincePostal, setStateProvincePostal] = useState("");
  const [postCodePostal, setPostCodePostal] = useState("");

  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationModalForLogout, setShowConfirmationModalForLogout] =
    useState(false);
  const [showConfirmationModalForBack, setShowConfirmationModalForBack] =
    useState(false);
  const [LogoutClicked, setLogoutClicked] = useState(false);
  const [backClicked, setBackClicked] = useState(false);

  // Function to handle closing the modal
  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
    // Additional logic if needed
  };

  const handleConfirmationYes = () => {
    navigate("/user/dashboard");
    setShowConfirmationModal(false);
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
  };
  const handlePasswordResetClick = () => {
    setShowPasswordModal(true);
  };

  const handleSubmit = async () => {
    // event.preventDefault();

    if (termsChecked) {
      setUnitFlatNumberPostal(unitFlatNumber);
      setStreatLotNumberPostal(streatLotNumber);
      setStreatNamePostal(streatName);
      setSuburbCityPostal(suburbCity);
      setStateProvincePostal(stateProvince);
      setPostCodePostal(postCode);
    }

    if (!termsChecked) {
      if (unitFlatNumberPostal.trim() !== "") {
      } else {
        setUnitFlatNumberPostal("");
      }

      if (streatLotNumberPostal.trim() !== "") {
      } else {
        setStreatLotNumberPostal("");
      }

      if (streatNamePostal.trim() !== "") {
      } else {
        setStreatNamePostal("");
      }

      if (suburbCityPostal.trim() !== "") {
      } else {
        setSuburbCityPostal("");
      }

      if (stateProvincePostal.trim() !== "") {
      } else {
        setStateProvincePostal("");
      }
      if (postCodePostal.trim() !== "") {
      } else {
        setPostCodePostal("");
      }
    }

    // Validation for Agent account type
    if (accountType === "Agent") {
      if (firstName.trim() === "") {
        toast.error("Please enter your first name.");
        document.getElementById("firstName").focus();

        return;
      }

      if (surname.trim() === "") {
        toast.error("Please enter your surname.");
        document.getElementById("surname").focus();
        return;
      }

      if (businessName.trim() === "") {
        toast.error("Please enter your business name.");
        document.getElementById("businessName").focus();
        return;
      }

      if (maraAgent === "yes" && marn.trim() === "") {
        toast.error("Please enter your MARN number.");
        document.getElementById("marn").focus();
        return;
      }
    }

    // Validation for Applicant account type
    if (accountType === "Applicant") {
      if (firstName.trim() === "") {
        toast.error("Please enter your first name.");
        document.getElementById("firstName").focus();
        return;
      }

      if (surname.trim() === "") {
        toast.error("Please enter your surname.");
        document.getElementById("surname").focus();
        return;
      }
    }

    // Validation for email
    if (email.trim() === "") {
      toast.error("Please enter your email.");
      document.getElementById("email").focus();
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email.trim())) {
      toast.error("Please enter a valid email address.");
      document.getElementById("email").focus();
      return;
    }

    // if (unitFlatNumber.trim() === "") {
    //   toast.error("Please enter your unit / flat number.");
    //   return;
    // }

    if (streatLotNumber.trim() === "") {
      toast.error("Please enter your street / lot number.");
      document.getElementById("streatLotNumber").focus();
      return;
    }

    if (streatName.trim() === "") {
      toast.error("Please enter your street name.");
      document.getElementById("streatName").focus();
      return;
    }

    if (suburbCity.trim() === "") {
      toast.error("Please enter your suburb / city.");
      document.getElementById("suburbCity").focus();
      return;
    }

    if (stateProvince.trim() === "") {
      toast.error("Please enter your state / province.");
      document.getElementById("stateProvince").focus();
      return;
    }

    if (postCode.trim() === "") {
      toast.error("Please enter your postcode.");
      document.getElementById("postCode").focus();
      return;
    }

    if (mobileCountryCode.trim() === "") {
      toast.error("Please enter your mobile country code.");
      return;
    }
    if (mobileNumber.trim() === "") {
      toast.error("Please enter your mobile phone number.");
      document.getElementById("mobileNumber").focus();

      return;
    }
    // if (telephoneCountryCode.trim() === "") {
    //   toast.error("Please enter your telephone country code.");
    //   return;
    // }
    // if (telephoneAreaCode.trim() === "") {
    //   toast.error("Please enter your telephone area code.");
    // document.getElementById('telephoneAreaCode').focus()
    //   return;
    // }
    // if (telephoneNumber.trim() === "") {
    //   toast.error("Please enter your telephone number.");
    //   return;
    // }

    if (!termsChecked) {
      // if (unitFlatNumberPostal.trim() === "") {
      //   toast.error("Please enter your postal unit / flat number.");
      //   return;
      // }

      if (streatLotNumberPostal.trim() === "") {
        toast.error("Please enter your postal street / lot number.");
        document.getElementById("streatLotNumberPostal").focus();
        return;
      }

      if (streatNamePostal.trim() === "") {
        toast.error("Please enter your postal street name.");
        document.getElementById("formBasicFormNamePostal").focus();

        return;
      }

      if (suburbCityPostal.trim() === "") {
        toast.error("Please enter your postal suburb / city.");
        document.getElementById("suburbCityPostal").focus();

        return;
      }

      if (stateProvincePostal.trim() === "") {
        toast.error("Please enter your postal state / province.");
        document.getElementById("stateProvincePostal").focus();

        return;
      }

      if (postCodePostal.trim() === "") {
        toast.error("Please enter your postal postcode.");
        document.getElementById("postcodePostal").focus();

        return;
      }
    }

    // Show success toast message
    // toast.success("Your profile has been updated successfully.");

    // Create a new FormData object
    const formData = new FormData();

    formData.append("user_id", userID);

    formData.append("name", firstName);
    formData.append("last_name", surname);
    formData.append("business_name", businessName);
    formData.append("mara_no", marn);
    formData.append("email", email);

    formData.append(
      "postal_ad_same_physical_ad_check",
      termsChecked ? "1" : "0"
    );

    formData.append("unit_flat", unitFlatNumber);
    formData.append("street_lot", streatLotNumber);
    formData.append("street_name", streatName);
    formData.append("suburb_city", suburbCity);
    formData.append("state_province", stateProvince);
    formData.append("postcode", postCode);
    formData.append("mobile_code", mobileCountryCode);
    formData.append("mobile_no", mobileNumber);
    formData.append("tel_code", telephoneCountryCode);
    formData.append("tel_area_code", telephoneAreaCode);
    formData.append("tel_no", telephoneNumber);

    formData.append(
      "postal_unit_flat",
      termsChecked ? unitFlatNumber : unitFlatNumberPostal
    );
    formData.append(
      "postal_street_lot",
      termsChecked ? streatLotNumber : streatLotNumberPostal
    );
    formData.append(
      "postal_street_name",
      termsChecked ? streatName : streatNamePostal
    );
    formData.append(
      "postal_suburb_city",
      termsChecked ? suburbCity : suburbCityPostal
    );
    formData.append(
      "postal_state_province",
      termsChecked ? stateProvince : stateProvincePostal
    );
    formData.append(
      "postal_postcode",
      termsChecked ? postCode : postCodePostal
    );

    // API call to update profile details
    try {
      setLoading(true);

      const response = await updateProfileAPI(formData);
      setLoading(false);

      if (response?.data?.response?.response === true) {
        navigate("/user/dashboard");
        toast.success("Your profile has been updated successfully.");
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to update Profile Details"
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Error saving Profile Details:", error);
      toast.error("Failed to update Profile Details");
    }
  };

  useEffect(() => {
    const getUserDetailsFunction = async () => {
      try {
        setLoading(true);
        const response = await getUserDataAPI(userID);
        setLoading(false);

        if (
          response &&
          response?.data &&
          response?.data?.response &&
          response?.data?.response?.user_details &&
          response?.data?.response?.response === true
        ) {
          const userProfileDetails = response?.data?.response?.user_details;

          if (
            userProfileDetails.postal_ad_same_physical_ad_check === "0" ||
            userProfileDetails.postal_ad_same_physical_ad_check === ""
          ) {
            setTermsChecked(false);
          } else if (
            userProfileDetails.postal_ad_same_physical_ad_check === "1"
          ) {
            setTermsChecked(true);
          }

          if (userProfileDetails.mobile_code === "") {
            setMobileCountryCode("13");
          } else {
            setMobileCountryCode(userProfileDetails.mobile_code);
          }

          if (userProfileDetails.tel_code === "") {
            setTelephoneCountryCode("13");
          } else {
            setTelephoneCountryCode(userProfileDetails.tel_code);
          }

          if (
            userProfileDetails.are_u_mara_agent === "" ||
            userProfileDetails.are_u_mara_agent === "no"
          ) {
            setMaraAgent("no");
          } else {
            setMaraAgent("yes");
          }

          setAccountType(userProfileDetails.account_type);

          setFirstName(userProfileDetails.name);
          setSurname(userProfileDetails.last_name);
          setBusinessName(userProfileDetails.business_name);
          setMarn(userProfileDetails.mara_no);
          setEmail(userProfileDetails.email);
          setUnitFlatNumber(userProfileDetails.unit_flat);
          setStreatLotNumber(userProfileDetails.street_lot);
          setStreatName(userProfileDetails.street_name);
          setSuburbCity(userProfileDetails.suburb_city);
          setStateProvince(userProfileDetails.state_province);
          setPostCode(userProfileDetails.postcode);
          setMobileNumber(userProfileDetails.mobile_no);
          setTelephoneAreaCode(userProfileDetails.tel_area_code);
          setTelephoneNumber(userProfileDetails.tel_no);
          setUnitFlatNumberPostal(userProfileDetails.postal_unit_flat);
          setStreatLotNumberPostal(userProfileDetails.postal_street_lot);
          setStreatNamePostal(userProfileDetails.postal_street_name);
          setSuburbCityPostal(userProfileDetails.postal_suburb_city);
          setStateProvincePostal(userProfileDetails.postal_state_province);
          setPostCodePostal(userProfileDetails.postal_postcode);
        } else {
          setLoading(false);

          console.error(
            "Error fetching occupations data:",
            response?.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        setLoading(false);

        console.error("Error fetching occupations data:", error);
      }
    };

    if (userID) {
      getUserDetailsFunction(userID);
    }
  }, [userID]);

  const handleEmailChange = (event) => {
    const input = event.target.value;
    setEmail(input);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(input))
      setEmailError("Please enter a valid email address.");
    else setEmailError("");
  };

  const handleLogout = () => {
    setShowConfirmationModalForLogout(true);
    setLogoutClicked(true);
  };

  const handleConfirmationForLogoutYes = () => {
    localStorage.clear();
    toast.success("Logged out successfully!");
    onLogout();

    if (onLogout) {
      navigate("/");
    }

    navigate("/");
  };

  const handleConfirmationForLogoutNo = () => {
    setShowConfirmationModalForLogout(false);
    setLogoutClicked(false);
  };

  const handleBack = () => {
    setShowConfirmationModalForBack(true);
    setBackClicked(true);
  };

  const handleConfirmationForBackYes = () => {
    navigate("/user/dashboard");
  };

  const handleConfirmationForBackNo = () => {
    setShowConfirmationModalForBack(false);
    setBackClicked(false);
  };

  const handleUpdatePersonalDetails = () => {
    navigate("/user/update_profile");
  };

  const handleEmailClick = () => {
    setShowMessage(true); // Show the message when the field is clicked or focused
  };


  return (
    <>
      {/* Top navigation */}
      <Row className="mb-2">
        {/* Back Button */}

        <Col>
          <Button
            className="back-button"
            variant=""
            onClick={handleBack}
            style={{
              // backgroundColor: "#f0f8ff",
              color: "#055837",
              marginLeft: "25px",
            }}
          >
            <BsArrowLeft className="me-0" /> Back to Dashboard
          </Button>
        </Col>

        <Col>
          <NavigationButtons
            handleUpdatePersonalDetails={handleUpdatePersonalDetails}
            handleLogout={handleLogout}
          />
        </Col>
      </Row>

      <Heading text="Update Personal Details" />



      {/* {loading && (
                  <div className="d-flex justify-content-center mt-3">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )} */}


{loading && (
 <Loader />
      )}



                
      {/* Agent Details */}
      {accountType === "Agent" && (
        <div className="forgot-container">


          <Card className="shadow forgot-card-applicant-agent" style={{marginTop:"20px"}}>
            <div
              className="card-header text-center"
              style={{ fontSize: "20px", color: "#055837" }}
            >
              <b>Agent Details</b>
            </div>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div className="account-details">
                  <div className="row mb-0">
                    <div className="col-md-3">
                      <Form.Group controlId="firstName">
                        <Form.Label htmlFor="firstName">
                          First / Given Name{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            placeholder="Enter your first name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            style={{ height: "40px" }}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-3">
                      <Form.Group controlId="surname">
                        <Form.Label htmlFor="surname">
                          Surname <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="surname"
                            name="surname"
                            placeholder="Enter your surname"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            style={{ height: "40px" }}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="businessName">
                        <Form.Label htmlFor="businessName">
                          Business Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="businessName"
                            name="businessName"
                            placeholder="Enter your business name"
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                            style={{ height: "40px" }}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>

                {maraAgent === "yes" && (
                  <div className="row mb-0">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="marn">
                          Migration Agent Registration Number (MARN){" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="marn"
                          name="marn"
                          placeholder="Enter your MARN"
                          value={marn}
                          onChange={(e) => setMarn(e.target.value)}
                          style={{ height: "40px" }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="email">
                        <Form.Label htmlFor="email">
                          Your Email <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          {/* <input
                            type="email"
                            className={`form-control ${
                              emailError ? "is-invalid" : ""
                            }`}
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={handleEmailChange}
                            style={{ height: "auto" }}
                          /> */}


<input
        type="email"
        className="form-control"
        id="email"
        name="email"
        placeholder="Enter your email"
        value={email}
        onChange={handleEmailChange}
        onClick={handleEmailClick} // Trigger message display when clicked
        onFocus={handleEmailClick} // Trigger message display when focused
        readOnly
        style={{ height: "auto", cursor: "not-allowed" }}
      />

      {showMessage && (
       <div style={{color:"red"}} className="mt-1">
      Contact the support team on
       {" "}
       <a
         href="mailto:skills@aqato.com.au" 
         style={{ color: "#055837", textDecoration: "underline" }} 
       >
       skills@aqato.com.au
       </a>
       {" "}
     if you want to change the email id attached to the account.
     </div>
      )}



                          {emailError && (
                            <div className="invalid-feedback">{emailError}</div>
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                )}
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}

      {/* Applicant Details */}
      {accountType === "Applicant" && (
        <div className="forgot-container">
          <Card className="shadow forgot-card-applicant-agent">
            <div
              className="card-header text-center"
              style={{ fontSize: "20px", color: "#055837" }}
            >
              <b>Applicant Details</b>
            </div>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div className="account-details">
                  <div className="row mb-0">
                    <div className="col-md-3">
                      <Form.Group controlId="firstName">
                        <Form.Label htmlFor="firstName">
                          First / Given Name{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            placeholder="Enter your first name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            style={{ height: "40px" }}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-3">
                      <Form.Group controlId="surname">
                        <Form.Label htmlFor="surname">
                          Surname <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="surname"
                            name="surname"
                            placeholder="Enter your surname"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            style={{ height: "40px" }}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="email">
                        <Form.Label htmlFor="email">
                          Your Email <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          {/* <input
                            type="email"
                            className={`form-control ${
                              emailError ? "is-invalid" : ""
                            }`}
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={handleEmailChange}
                            style={{ height: "auto" }}
                          /> */}

                
<input
        type="email"
        className="form-control"
        id="email"
        name="email"
        placeholder="Enter your email"
        value={email}
        onChange={handleEmailChange}
        onClick={handleEmailClick} // Trigger message display when clicked
        onFocus={handleEmailClick} // Trigger message display when focused
        readOnly
        style={{ height: "auto", cursor: "not-allowed" }}
      />

      {showMessage && (
       <div style={{color:"red"}} className="mt-1">
      Contact the support team on
       {" "}
       <a
         href="mailto:skills@aqato.com.au" 
         style={{ color: "#055837", textDecoration: "underline" }} 
       >
       skills@aqato.com.au
       </a>
       {" "}
     if you want to change the email id attached to the account.
     </div>
      )}


                          {emailError && (
                            <div className="invalid-feedback">{emailError}</div>
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}

      {/* Contact Details */}

      <div className="forgot-container">
        <Card className="shadow forgot-card-applicant-agent">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Contact Details</b>
          </div>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <div className="account-details">
                <div className="row mb-0">
                  <div className="col-md-6">
                    <Form.Group controlId="formBasicBusinessName">
                      <Form.Label>Unit / Flat Number</Form.Label>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="Unit / Flat Number"
                          name="Unit / Flat Number"
                          placeholder="Enter your Unit / Flat Number"
                          value={unitFlatNumber}
                          onChange={(e) => setUnitFlatNumber(e.target.value)}
                          style={{ height: "40px" }}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group controlId="streatLotNumber">
                      <Form.Label htmlFor="streatLotNumber">
                        Street / Lot number{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <div className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          id="streatLotNumber"
                          name="streatLotNumber"
                          placeholder="Enter your Street / Lot number"
                          value={streatLotNumber}
                          onChange={(e) => setStreatLotNumber(e.target.value)}
                          style={{ height: "40px" }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="streatName">
                      Street Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="streatName"
                      name="streatName"
                      placeholder="Enter your Street Name"
                      value={streatName}
                      onChange={(e) => setStreatName(e.target.value)}
                      style={{ height: "40px" }}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <Form.Group controlId="suburbCity">
                    <Form.Label htmlFor="suburbCity">
                      Suburb / City <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control ${
                          emailError ? "is-invalid" : ""
                        }`}
                        id="suburbCity"
                        name="suburbCity"
                        placeholder="Enter your Suburb / City"
                        value={suburbCity}
                        onChange={(e) => setSuburbCity(e.target.value)}
                        style={{ height: "auto" }}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="stateProvince">
                      State / Province <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="stateProvince"
                      name="stateProvince"
                      placeholder="Enter your State / Province"
                      value={stateProvince}
                      onChange={(e) => setStateProvince(e.target.value)}
                      style={{ height: "40px" }}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <Form.Group controlId="postCode">
                    <Form.Label htmlFor="postCode">
                      Postcode <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <div className="form-group">
                      <input
                        type="number"
                        className={`form-control ${
                          emailError ? "is-invalid" : ""
                        }`}
                        id="postCode"
                        name="postCode"
                        placeholder="Enter your Postcode"
                        value={postCode}
                        onChange={(e) => setPostCode(e.target.value)}
                        style={{ height: "auto" }}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>

              <div className="account-details">
                <div className="row mb-0">
                  <div className="col-md-6">
                    <Form.Group controlId="formBasicTelephone">
                      <Form.Label>
                        Mobile <span style={{ color: "red" }}>*</span>{" "}
                      </Form.Label>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Label className="small">
                            Country Code
                          </Form.Label>
                          <select
                            className="form-select"
                            style={{ height: "40px" }}
                            value={mobileCountryCode}
                            onChange={(e) =>
                              setMobileCountryCode(e.target.value)
                            }
                          >
                            <option value="">Select Country Code</option>{" "}
                            {/* Map over countryyyyyyyyyyyyyys to generate options */}
                            {countriesData.map((countryyyyyyyyyyyyyy) => (
                              <option
                                key={countryyyyyyyyyyyyyy.id}
                                className="dropdown-options"
                                value={countryyyyyyyyyyyyyy.id}
                              >
                                {countryyyyyyyyyyyyyy.name} (+
                                {countryyyyyyyyyyyyyy.phonecode})
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Phone Number */}

                        <div className="col-md-6">
                          <Form.Group controlId="mobileNumber">
                            <Form.Label
                              className="small"
                              htmlFor="mobileNumber"
                            >
                              Phone Number
                            </Form.Label>
                            <div className="form-group">
                              <input
                                id="mobileNumber"
                                name="mobileNumber"
                                type="number"
                                className="form-control"
                                placeholder="Enter Your Phone Number"
                                style={{ height: "40px" }}
                                value={mobileNumber}
                                onChange={(e) =>
                                  setMobileNumber(e.target.value)
                                }
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group controlId="formBasicTelephone">
                      <Form.Label>Telephone</Form.Label>
                      <div className="row">
                        {/* Country Code */}
                        <div className="col-md-3">
                          <Form.Label className="small">
                            Country Code
                          </Form.Label>
                          <select
                            className="form-select"
                            style={{ height: "40px" }}
                            value={telephoneCountryCode}
                            onChange={(e) =>
                              setTelephoneCountryCode(e.target.value)
                            }
                          >
                            <option value="">Select Country Code</option>{" "}
                            {/* Map over countryyyyyyyyyyyyyys to generate options */}
                            {countriesData.map((countryyyyyyyyyyyyyy) => (
                              <option
                                key={countryyyyyyyyyyyyyy.id}
                                className="dropdown-options"
                                value={countryyyyyyyyyyyyyy.id}
                              >
                                {countryyyyyyyyyyyyyy.name} (+
                                {countryyyyyyyyyyyyyy.phonecode})
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Area Code */}
                        <div className="col-md-3">
                          <Form.Group controlId="telephoneAreaCode">
                            <Form.Label className="small">Area Code</Form.Label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Area Code"
                              style={{ height: "40px" }}
                              value={telephoneAreaCode}
                              onChange={(e) =>
                                setTelephoneAreaCode(e.target.value)
                              }
                            />
                          </Form.Group>
                        </div>

                        {/* Phone Number */}
                        <div className="col-md-6">
                          <Form.Label className="small">
                            Phone Number
                          </Form.Label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Your Phone Number"
                            style={{ height: "40px" }}
                            value={telephoneNumber}
                            onChange={(e) => setTelephoneNumber(e.target.value)}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>

      {/* Action Pannel */}

      <div className="forgot-container">
        <Card className="shadow forgot-card-varification">
          {/* <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Action Panel</b>
          </div> */}

          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <div className="account-details">
                <div
                  className="form-group form-check mb-1"
                  style={{
                    marginTop: "12px",
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <label
                    className="form-check-label"
                    htmlFor="termsAndConditions"
                    style={{ fontSize: "15px", fontWeight: "bold" }}
                  >
                    Postal Address Same As Physical Address ?
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="termsAndConditions"
                      name="termsAndConditions"
                      checked={termsChecked}
                      onChange={() => setTermsChecked(!termsChecked)}
                      style={{
                        transform: "scale(1.5)",
                        border: "1px solid #055837",
                    color: "#055837",
                    backgroundColor: termsChecked ? "#055837" : "",       marginLeft: "20px",
                      }}
                    />
                  </label>
                </div>

                {/* Postal Address Conatact Details */}
                {!termsChecked && (
                  <div>
                    {/* <Card> */}
                    <Card className="shadow forgot-card-varification-postal-address">
                      <div
                        className="card-header text-center"
                        style={{ fontSize: "20px", color: "#055837" }}
                      >
                        <b>Postal Address Details</b>
                      </div>

                      <Card.Body>
                        <Form onSubmit={handleSubmit}>
                          <div className="account-details">
                            <div className="row mb-0">
                              <div className="col-md-6">
                                <Form.Group controlId="formBasicFormNumberPostal">
                                  <Form.Label>Unit / Flat Number</Form.Label>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Unit / Flat Number"
                                      name="Unit / Flat Number"
                                      placeholder="Enter your Unit / Flat Number"
                                      value={setUnitFlatNumberPostal}
                                      onChange={(e) =>
                                        setUnitFlatNumberPostal(e.target.value)
                                      }
                                      style={{ height: "40px" }}
                                    />
                                  </div>
                                </Form.Group>
                              </div>

                              <div className="col-md-6">
                                <Form.Group controlId="streatLotNumberPostal">
                                  <Form.Label htmlFor="streatLotNumberPostal">
                                    Street / Lot number{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Form.Label>
                                  <div className="form-group">
                                    <input
                                      type="number"
                                      className="form-control"
                                      id="streatLotNumberPostal"
                                      name="streatLotNumberPostal"
                                      placeholder="Enter your Street / Lot number"
                                      value={streatLotNumberPostal}
                                      onChange={(e) =>
                                        setStreatLotNumberPostal(e.target.value)
                                      }
                                      style={{ height: "40px" }}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          </div>

                          <div className="row mb-0">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="formBasicFormNamePostal">
                                  Street Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="formBasicFormNamePostal"
                                  name="formBasicFormNamePostal"
                                  placeholder="Enter your Street Name"
                                  value={streatNamePostal}
                                  onChange={(e) =>
                                    setStreatNamePostal(e.target.value)
                                  }
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <Form.Group controlId="formBasicSuburbPostal">
                                <Form.Label htmlFor="suburbCityPostal">
                                  Suburb / City{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      emailError ? "is-invalid" : ""
                                    }`}
                                    id="suburbCityPostal"
                                    name="suburbCityPostal"
                                    placeholder="Enter your Suburb / City"
                                    value={suburbCityPostal}
                                    onChange={(e) =>
                                      setSuburbCityPostal(e.target.value)
                                    }
                                    style={{ height: "auto" }}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="row mb-0">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="stateProvincePostal">
                                  State / Province{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="stateProvincePostal"
                                  name="stateProvincePostal"
                                  placeholder="Enter your State / Province"
                                  value={stateProvincePostal}
                                  onChange={(e) =>
                                    setStateProvincePostal(e.target.value)
                                  }
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <Form.Group controlId="formBasicPostcodeNumberPostal">
                                <Form.Label>
                                  Postcode{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <div className="form-group">
                                  <input
                                    type="number"
                                    className={`form-control ${
                                      emailError ? "is-invalid" : ""
                                    }`}
                                    id="postcodePostal"
                                    name="postcodePostal"
                                    placeholder="Enter your Postcode"
                                    value={postCodePostal}
                                    onChange={(e) =>
                                      setPostCodePostal(e.target.value)
                                    }
                                    style={{ height: "auto" }}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </Form>
                      </Card.Body>
                    </Card>
                  </div>
                )}

                {/* {loading && (
                  <div className="d-flex justify-content-center mt-3">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                )} */}



{loading && (
 <Loader />
      )}



                {/* update and Cancel Buttons */}
                <div
                  className="button-group text-center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "12px",
                  }}
                >
                  {/* update Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    style={{
                      width: "100px",
                      backgroundColor: "#055837",
                      color: "#ffcc01",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      // padding: "0px 5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                  >
                    Update
                  </button>

                  {/* Cancel Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      width: "100px",
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onClick={() => setShowConfirmationModal(true)}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Cancel
                  </button>
                </div>

                <div
                  className="button-group text-center mb-1"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    // onClick={handleShowPasswordModal}
                    onClick={handlePasswordResetClick}
                    style={{
                      width: "180px",
                      backgroundColor: "red",
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      // marginTop: "10px",
                      marginBottom: "5px",
                      transition: "transform 0.1s",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.transform = "scale(1.01)";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.transform = "scale(1)";
                    }}
                  >
                    Password Reset
                  </button>
                </div>
                <PasswordResetModal
                  show={showPasswordModal}
                  handleClose={handleClosePasswordModal}
                  userID={userID}
                />
              </div>
            </Form>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Exit"
            message="Are you sure you want to discard the changes and exit the profile update process?"
          />
        </Card>
      </div>

      {LogoutClicked && (
        <ConfirmationModal
          show={showConfirmationModalForLogout}
          onHide={handleConfirmationForLogoutNo}
          onConfirm={handleConfirmationForLogoutYes}
          title="Confirm Logout"
          message="Are you sure you want to log out?"
        />
      )}

      {backClicked && (
        <ConfirmationModal
          show={showConfirmationModalForBack}
          onHide={handleConfirmationForBackNo}
          onConfirm={handleConfirmationForBackYes}
          title="Confirm Back"
          message="Going back will discard any unupdated changes. You will be redirected to the Dashboard page. Continue?"
        />
      )}
    </>
  );
};

export default UpdateProfile;
