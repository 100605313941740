import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Heading from "../Heading/Heading";
import "./Create New Account.css";
import { Card, Form } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createNewAccountAPI } from "../../api";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CaptchaComponent from "../Captcha Component/Captcha Component";
import ConfirmationModal from "../Confirmation Modal/ConfirmationModal";
import { Modal, Button } from "react-bootstrap";
import Loader from "../Loader/Loader";

const CreateNewAccount = () => {
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState("Agent");
  const [accountType, setAccountType] = useState("Agent");
  const [maraAgent, setMaraAgent] = useState("yes");
  const [termsChecked, setTermsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [marn, setMarn] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [showConfirmationSuccessModal, setShowConfirmationSuccessModal] =
    useState(false);
  const [accountCreatedSuccessfully, setAccountCreatedSuccessfully] =
    useState(false);
  const handleConfirmationAccountCreatedNo = () => {
    setShowConfirmationSuccessModal(false);
  };
  const handleConfirmationAccountCreatedYes = () => {
    setShowConfirmationSuccessModal(false);
    navigate("/");
    setAccountCreatedSuccessfully(false);
    toast.success(
      "Account created successfully, kindly check to your nominated E-mail."
    );
  };

  const handleSuccessful = () => {
    setAccountCreatedSuccessfully(true);
    setShowConfirmationSuccessModal(true);
  };

  const handleCaptchaVerification = (isVerified) => {
    setIsCaptchaVerified(isVerified);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email.trim() === "") {
      toast.error("Please enter your email.");
      document.getElementById("email").focus();
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email.trim())) {
      toast.error("Please enter a valid email address.");
      document.getElementById("email").focus();
      return;
    }

    if (password.trim() === "") {
      toast.error("Please enter your password.");
      document.getElementById("password").focus();
      return;
    }

    if (passwordError) {
      toast.error(
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one symbol."
      );
      document.getElementById("password").focus();
      return;
    }

    if (confirmPassword.trim() === "") {
      toast.error("Please confirm your password.");
      document.getElementById("confirmPassword").focus();
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      setConfirmPasswordError("Passwords do not match.");
      document.getElementById("confirmPassword").focus();
      return;
    }

    if (accountType === "Agent") {
      if (firstName.trim() === "") {
        toast.error("Please enter your first name.");
        document.getElementById("firstName").focus();
        return;
      }

      if (surname.trim() === "") {
        toast.error("Please enter your surname.");
        document.getElementById("surname").focus();
        return;
      }

      if (businessName.trim() === "") {
        toast.error("Please enter your business name.");
        document.getElementById("businessName").focus();
        return;
      }

      if (maraAgent === "yes" && marn.trim() === "") {
        toast.error("Please enter your MARN number.");
        document.getElementById("marn").focus();
        return;
      }
    } else if (accountType === "Applicant") {
      if (firstName.trim() === "") {
        toast.error("Please enter your first name.");
        document.getElementById("firstName").focus();
        return;
      }

      if (surname.trim() === "") {
        toast.error("Please enter your surname.");
        document.getElementById("surname").focus();
        return;
      }
    }

    if (!isCaptchaVerified) {
      toast.error("Verification code does not match.");
      return;
    }

    if (!termsChecked) {
      toast.error("Please agree to the terms and conditions.");
      document.getElementById("termsAndConditions").focus();
      return;
    }

    // If all validations pass, create form data
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("cpassword", confirmPassword);
    // formData.append("agent_or_not", accountType === "Agent" ? "yes" : "no");
    formData.append("migration-number", maraAgent === "yes" ? marn : "");
    formData.append("business-name", businessName);
    formData.append("given-name", firstName);
    formData.append("surname", surname);
    formData.append("account_type", accountType);

    formData.append("are_u_mara_agent", maraAgent);

    // Set loading to true to show loading icon
    setIsLoading(true);

    // Make the API call
    const response = await createNewAccountAPI(formData);
    // Reset loading to false after API call is completed
    setIsLoading(false);

    const responseData = response.data.response;
    if (responseData && responseData.response === true) {
      handleSuccessful();
    } else {
      if (
        responseData.error_msg ===
        "Sorry! Your Email is already registered, Try to Forgot Password ?"
      ) {
        toast.error(
          "Sorry! Your Email is already registered, Try to Forgot Password ?"
        );
      } else if (responseData.error_msg) {
        toast.error(responseData.error_msg);
      } else {
        toast.error(
          "An error occurred during creating account. Please try again."
        );
      }
    }
  };

  const handleEmailChange = (event) => {
    const input = event.target.value;
    setEmail(input);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(input))
      setEmailError("Please enter a valid email address.");
    else setEmailError("");
  };

  const handlePasswordChange = (event) => {
    const input = event.target.value;
    setPassword(input);

    // Password validation criteria
    const hasUpperCase = /[A-Z]/.test(input);
    const hasLowerCase = /[a-z]/.test(input);
    const hasNumber = /\d/.test(input);
    const hasSymbol = /[$&+,:;=?@#|'<>.^*()%!-]/.test(input);
    const isLengthValid = input.length >= 8 && input.length <= 20;

    // Check if all criteria are met
    if (
      isLengthValid &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSymbol
    ) {
      setPasswordError("");
    } else {
      setPasswordError(
        "Password must be 8-20 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one symbol."
      );
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const input = event.target.value;
    setConfirmPassword(input);

    if (input !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      // Clear the error message when passwords match
      setConfirmPasswordError("");
    }
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setAccountType(event.target.value);
  };

  // Function to handle closing the modal
  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
    // Additional logic if needed
  };

  const handleConfirmationYes = () => {
    navigate("/");
    setShowConfirmationModal(false);
  };

  return (
    <>
      <Heading text="Create New Account" />

      <div className="forgot-container">
        <Card className="shadow forgot-card-account-details shadow">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Account Details</b>
          </div>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <div className="account-details">
                <div className="row mb-0">
                  <div className="col-md-6">
                    <Form.Group controlId="formBasicSelect">
                      <Form.Label>
                        Account Type <span style={{ color: "red" }}>*</span>{" "}
                      </Form.Label>
                      <div className="form-group">
                        <Form.Select
                          value={selectedOption}
                          onChange={handleOptionChange}
                        >
                          <option className="dropdown-options" value="Agent">
                            Agent
                          </option>
                          <option
                            className="dropdown-options"
                            value="Applicant"
                          >
                            Applicant
                          </option>
                        </Form.Select>
                      </div>
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label htmlFor="email">
                        Your Email <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <div className="form-group">
                        <input
                          type="email"
                          className={`form-control ${
                            emailError ? "is-invalid" : ""
                          }`}
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={handleEmailChange}
                          aria-describedby="emailError"
                          style={{ height: "auto" }}
                        />
                        {emailError && (
                          <div id="emailError" className="invalid-feedback">
                            {emailError}
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row mb-0">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="password" style={{ display: "block" }}>
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <div
                        className="input-group"
                        style={{ position: "relative" }}
                      >
                        <input
                          type={showPassword ? "text" : "password"}
                          className={`form-control ${
                            passwordError ? "is-invalid" : ""
                          }`}
                          id="password"
                          name="password"
                          placeholder="Enter your password"
                          value={password}
                          onChange={handlePasswordChange}
                          style={{ height: "40px" }}
                          aria-describedby="passwordError"
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                              height: "40px",
                              borderRadius: "0 5px 5px 0",
                            }}
                          >
                            {showPassword ? (
                              <FaEyeSlash
                                size={20}
                                style={{ borderRadius: "0 5px 5px 0" }}
                              />
                            ) : (
                              <FaEye
                                size={20}
                                style={{ borderRadius: "0 5px 5px 0" }}
                              />
                            )}
                          </button>
                        </div>
                        {passwordError && (
                          <div id="passwordError" className="invalid-feedback">
                            {passwordError}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="confirmPassword"
                        style={{ display: "block" }}
                      >
                        Confirm Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          className={`form-control ${
                            confirmPasswordError ? "is-invalid" : ""
                          }`}
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Confirm your password"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          style={{ height: "40px" }}
                          aria-describedby="confirmPasswordError"
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            onClick={toggleConfirmPasswordVisibility}
                            style={{
                              height: "40px",
                              borderRadius: "0 5px 5px 0",
                            }}
                          >
                            {showConfirmPassword ? (
                              <FaEyeSlash
                                size={20}
                                style={{ borderRadius: "0 5px 5px 0" }}
                              />
                            ) : (
                              <FaEye
                                size={20}
                                style={{ borderRadius: "0 5px 5px 0" }}
                              />
                            )}
                          </button>
                        </div>
                        {confirmPasswordError && (
                          <div
                            id="confirmPasswordError"
                            className="invalid-feedback"
                          >
                            {confirmPasswordError}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>

      {/* Agent Details */}
      {accountType === "Agent" && (
        <div className="forgot-container">
          <Card className="shadow forgot-card-agent shadow">
            <div
              className="card-header text-center"
              style={{ fontSize: "20px", color: "#055837" }}
            >
              <b>Agent Details</b>
            </div>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div className="account-details">
                  <div className="row mb-0">
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicFirstName">
                        <Form.Label htmlFor="firstName">
                          First / Given Name{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            placeholder="Enter your first name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            style={{ height: "40px" }}
                            aria-describedby="firstNameError"
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="formBasicSurname">
                        <Form.Label htmlFor="surname">
                          Surname <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="surname"
                            name="surname"
                            placeholder="Enter your surname"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            style={{ height: "40px" }}
                            aria-describedby="surnameError"
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="account-details">
                  <div className="row mb-0">
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicBusinessName">
                        <Form.Label htmlFor="businessName">
                          Business Name <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="businessName"
                            name="businessName"
                            placeholder="Enter your business name"
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                            style={{ height: "40px" }}
                            aria-describedby="businessNameError"
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="formBasicMaraAgent">
                        <Form.Label>
                          Are you a MARA Agent?{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>

                        <div className="radio-buttons">
                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="maraAgent"
                              value="yes"
                              checked={maraAgent === "yes"}
                              onChange={() => setMaraAgent("yes")}
                              style={{
                                marginRight: "5px",
                                border: "2px solid #ccc",
                                borderRadius: "50%",
                                width: "22px",
                                height: "22px",
                                cursor: "pointer",
                              }}
                            />
                            <label className="form-check-label">Yes</label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="maraAgent"
                              value="no"
                              checked={maraAgent === "no"}
                              onChange={() => setMaraAgent("no")}
                              style={{
                                marginRight: "5px",
                                border: "2px solid #ccc",
                                borderRadius: "50%",
                                width: "22px",
                                height: "22px",
                                cursor: "pointer",
                              }}
                            />
                            <label className="form-check-label">No</label>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>

                {maraAgent === "yes" && (
                  <div className="row mb-0">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="marn">
                          Migration Agent Registration Number (MARN){" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="marn"
                          name="marn"
                          placeholder="Enter your MARN"
                          value={marn}
                          onChange={(e) => setMarn(e.target.value)}
                          style={{ height: "40px" }}
                          aria-describedby="marnError"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}

      {/* Applicant Details */}
      {accountType === "Applicant" && (
        <div className="forgot-container">
          <Card className="shadow forgot-card-agent shadow">
            <div
              className="card-header text-center"
              style={{ fontSize: "20px", color: "#055837" }}
            >
              <b>Applicant Details</b>
            </div>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div className="account-details">
                  <div className="row mb-0">
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicFirstName">
                        <Form.Label htmlFor="firstName">
                          First / Given Name{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            placeholder="Enter your first name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            style={{ height: "40px" }}
                            aria-describedby="firstNameError"
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-6">
                      <Form.Group controlId="formBasicSurname">
                        <Form.Label htmlFor="surname">
                          Surname <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="surname"
                            name="surname"
                            placeholder="Enter your surname"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                            style={{ height: "40px" }}
                            aria-describedby="surnameError"
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      )}

      <div className="forgot-container mb-0">
        <Card
          className="shadow forgot-card-varification shadow"
          style={{ marginTop: "25px" }}
        >
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Verification & Agreement</b>
          </div>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <div className="account-details">
                <CaptchaComponent
                  onCaptchaVerification={handleCaptchaVerification}
                />

                {/* Cookies Notice */}
                <div
                  className="cookies-notice mb-2"
                  style={{ textAlign: "center", marginTop: "30px" }}
                >
                  <h3 style={{ color: "rgb(255 193 7)", fontSize: "25px" }}>
                    Cookies Notice
                  </h3>
                  <p
                    style={{
                      fontFamily: "Tahoma, Geneva, sans-serif",
                      fontSize: "14px",
                    }}
                  >
                    This website uses Cookies which are used to allow us to
                    track the number of people visiting the site, the pages that
                    they visit and how long they stay on each page. This
                    information allows us to continually develop and improve the
                    service we offer our website visitors, and to ensure that we
                    are meeting our key priority of keeping you informed.
                  </p>
                </div>

                {/* Terms and Conditions */}
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ marginTop: "30px" }}
                >
                  <div className="form-group form-check mb-1">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="termsAndConditions"
                      name="termsAndConditions"
                      checked={termsChecked}
                      onChange={() => setTermsChecked(!termsChecked)}
                      style={{
                        transform: "scale(1.0)",
                        border: "1px solid #055837",
                        cursor: "pointer",
                      }}
                      aria-describedby="termsError"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="termsAndConditions"
                    >
                      I agree and accept the terms and conditions
                    </label>
                    {!termsChecked && (
                      <div id="termsError" className="invalid-feedback">
                        * Please agree to the terms and conditions!
                      </div>
                    )}
                  </div>
                </div>

                {/* {isLoading && <div>Loading...</div>} */}
                {isLoading && <Loader />}

                {/* Register and Cancel Buttons */}
                <div
                  className="button-group text-center mb-2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {/* Register Button */}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    style={{
                      width: "100px",
                      backgroundColor: "#055837",
                      color: "#ffcc01",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      // padding: "0px 5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                      cursor: "pointer", // Add cursor pointer
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                  >
                    Register
                  </button>

                  {/* Cancel Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      width: "100px",
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                      cursor: "pointer", // Add cursor pointer
                    }}
                    onClick={() => setShowConfirmationModal(true)}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Cancel
                  </button>
                </div>

                <div
                  className="d-flex justify-content-center"
                  style={{ marginBottom: "10px" }}
                >
                  <p>
                    Already have an account?{" "}
                    <a
                      style={{
                        fontWeight: "bold",
                        color: "#055837",
                        textDecoration: "none",
                        transition: "text-decoration 0.3s",
                        cursor: "pointer", // Corrected cursor syntax
                      }}
                      onClick={() => navigate("/")}
                      onMouseEnter={(e) => {
                        e.target.style.textDecoration = "underline";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.textDecoration = "none";
                      }}
                    >
                      Log in
                    </a>
                  </p>
                </div>
              </div>
            </Form>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Exit"
            message="Are you sure you want to cancel creating a new account? Any information entered will be lost."
          />

          {accountCreatedSuccessfully && (
            <Modal show={showConfirmationSuccessModal} centered>
              <Modal.Header>
                <Modal.Title
                  className="text-center w-100"
                  style={{
                    fontSize: "18px",
                    fontFamily: "Arial, sans-serif",
                    color: "#055837",
                  }}
                >
                  Account Created Successfully !
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                className="text-left"
                style={{ fontSize: "16px", fontFamily: "Arial, sans-serif" }}
              >
                <ul style={{ listStyle: "none", paddingLeft: "0" }}>
                  <li style={{ marginBottom: "10px" }}>
                    <span style={{ color: "black" }}>•</span> Thank you{" "}
                    <strong>{firstName}</strong>, for creating an account.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <span style={{ color: "black" }}>•</span> An email has been
                    sent to your nominated email address (
                    <strong>{email}</strong>) with your account activation link.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <span style={{ color: "black" }}>•</span> Please check your
                    Inbox/Junk Mail and follow the link to activate your
                    account.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <span style={{ color: "black" }}>•</span> If you do not
                    receive a notification email from us within the next 15
                    minutes, please contact us at{" "}
                    <a href="mailto:skills@aqato.com.au">skills@aqato.com.au</a>{" "}
                    for assistance.
                  </li>
                </ul>
              </Modal.Body>

              <Modal.Footer className="d-flex justify-content-center">
                <Button
                  variant="light"
                  onClick={handleConfirmationAccountCreatedYes}
                  className="w-50"
                  style={{
                    maxWidth: "130px",
                    backgroundColor: "#055837",
                    color: "#ffcc01",
                    transition: "transform 0.2s",
                  }}
                  onMouseEnter={(e) =>
                    (e.target.style.transform = "scale(1.02)")
                  }
                  onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Card>
      </div>
    </>
  );
};

export default CreateNewAccount;
