import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "./USI & Avetmiss.css";
import ConfirmationModal from "../../../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createUSIandAvetmiss_stage1_API,
  getUSIandAvetmiss_stage1_API,
} from "../../../../api";
import { useLocation } from "react-router-dom";
import Loader from "../../../Loader/Loader";

const USIAndAvetmiss = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [currentlyInAustralia, setCurrentlyInAustralia] = useState();
  const [attcPermission, setAttcPermission] = useState(false);
  const [USITranscript, setUSITranscript] = useState("");
  const [USI, setUSI] = useState("");

  const [languageOtherThanEnglish, setLanguageOtherThanEnglish] =
    useState("yes");
  const [specifyLanguage, setSpecifyLanguage] = useState("");

  const [proficientInEnglish, setProficientInEnglish] = useState("");

  const [aboriginal, setAboriginal] = useState("");
  const [originOption, setOriginOption] = useState("");

  const [disabilityOrimpairment, setDisabilityOrimpairment] = useState("");
  const [indiacteArea, setIndiacateArea] = useState("");

  const [additionalSupportToParticipate, setAdditionalSupportToParticipate] =
    useState("");
  const [specifySupportYouNeed, setSpecifySupportYouNeed] = useState("");

  const [
    marketingAustralianTradeTrainingCollagePermission,
    setMarketingAustralianTradeTrainingCollagePermission,
  ] = useState("");

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [showConfirmationBackModal, setShowConfirmationBackModal] =
    useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const handleConfirmationBackNo = () => {
    setShowConfirmationBackModal(false);
  };
  const handleConfirmationBackYes = () => {
    navigate("/user/create_new_application/stage_1/identification", {
      state: { pointerData },
    });
    setShowConfirmationBackModal(false);
    toast.success("Navigated to Identification form page.");
  };

  const handleBack = () => {
    // setBackButtonClicked(true);
    // setShowConfirmationBackModal(true);

    navigate("/user/create_new_application/stage_1/identification", {
      state: { pointerData },
    });
    toast.success("Navigated to Identification form page.");
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationYes = async () => {
    // navigate("/user/dashboard");
    // setShowConfirmationModal(false);
    // toast.success("USI & Avetmiss Details saved successfully.");

    // Construct form data object
    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append("currently_have_usi", currentlyInAustralia ? "yes" : "no");
    formData.append("usi_no", currentlyInAustralia ? USI : "");
    formData.append(
      "have_usi_transcript",
      currentlyInAustralia ? USITranscript : ""
    );
    formData.append(
      "permission_access_usi_transcripts",
      currentlyInAustralia && USITranscript === "yes"
        ? attcPermission
          ? "yes"
          : "no"
        : ""
    );

    formData.append("speak_english_at_home", languageOtherThanEnglish);
    formData.append(
      "specify_language",
      languageOtherThanEnglish === "yes" ? specifyLanguage : ""
    );
    formData.append("proficiency_in_english", proficientInEnglish);
    formData.append("are_you_aboriginal", aboriginal);
    formData.append("choose_origin", aboriginal === "yes" ? originOption : "");
    formData.append("have_any_disability", disabilityOrimpairment);
    formData.append(
      "indicate_area",
      disabilityOrimpairment === "yes" ? indiacteArea : ""
    );
    formData.append(
      "require_additional_support",
      additionalSupportToParticipate
    );
    formData.append(
      "note",
      additionalSupportToParticipate === "yes" ? specifySupportYouNeed : ""
    );
    formData.append(
      "marketing",
      marketingAustralianTradeTrainingCollagePermission
    );

    // API call to save USI & Avetmiss details
    try {
      setLoading(true);

      const response = await createUSIandAvetmiss_stage1_API(formData);
      setLoading(false);

      if (response?.data?.response?.response === true) {
        navigate("/user/dashboard");
        setShowConfirmationModal(false);
        toast.success("USI & Avetmiss Details saved successfully.");
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save USI & Avetmiss Details"
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Error saving USI & Avetmiss Details:", error);
      toast.error("Failed to save USI & Avetmiss Details");
    }
  };

  const handleNext = async () => {
    // event.preventDefault();

    if (currentlyInAustralia !== true && currentlyInAustralia !== false) {
      toast.error(
        "Please select your location. Choose either 'In Australia' or 'Outside of Australia/Offshore'."
      );
      document.getElementById("currentlyInAustraliaRadio").focus();
      document.getElementById("outsideOfAustraliaOrOffshoreRadio").focus();
      return;
    }

    if (currentlyInAustralia === true) {
      if (USI.trim() === "") {
        toast.error("Please enter your USI (Unique Student Identifier).");
        document.getElementById("usiInput").focus();
        return;
      }

      if (USITranscript.trim() === "") {
        toast.error("Please select whether you have a USI Transcript.");

        document.getElementById("usiTranscriptYes").focus();
        return;
      }

      if (USITranscript.trim() === "yes" && attcPermission === false) {
        toast.error(
          "Please mark the checkbox to grant ATTC permission to access your USI Transcripts."
        );

        document.getElementById("attcPermissionCheckbox").focus();
        return;
      }
    }

    if (languageOtherThanEnglish.trim() === "") {
      toast.error(
        "Please indicate whether you speak a language other than English at home."
      );
      document.getElementById("languageOtherThanEnglishYes").focus();
      return;
    }

    if (languageOtherThanEnglish.trim() === "yes") {
      if (specifyLanguage.trim() === "") {
        toast.error("Please specify the language spoken at home.");
        document.getElementById("languageSpecificationInput").focus();
        return;
      }
    }

    if (proficientInEnglish.trim() === "") {
      toast.error("Please select your proficiency level in Spoken English.");
      document.getElementById("englishProficiencySelect").focus();
      return;
    }

    if (aboriginal.trim() === "") {
      toast.error(
        "Please indicate whether you are of Aboriginal or Torres Strait Islander Origin."
      );
      document.getElementById("aboriginalOriginRadio").focus();
      return;
    }

    if (aboriginal.trim() === "yes") {
      if (originOption.trim() === "") {
        toast.error("Please choose an origin option.");
        document.getElementById("chooseOriginOption").focus();
        return;
      }
    }

    if (disabilityOrimpairment.trim() === "") {
      toast.error(
        "Please indicate if you consider yourself to have a disability, impairment, or long-term condition."
      );
      document.getElementById("disabilityOrImpairment").focus();
      return;
    }

    if (disabilityOrimpairment.trim() === "yes") {
      if (indiacteArea.trim() === "") {
        toast.error("Please indicate the disability area.");
        document.getElementById("specifyAreaSelect").focus();
        return;
      }
    }

    if (additionalSupportToParticipate.trim() === "") {
      toast.error(
        "Please indicate if you will require additional support to participate in this skills assessment."
      );
      document.getElementById("additionalSupportRadio").focus();
      return;
    }

    if (additionalSupportToParticipate.trim() === "yes") {
      if (specifySupportYouNeed.trim() === "") {
        toast.error("Please specify the type of support you need.");
        document.getElementById("specifySupportInput").focus();
        return;
      }
    }

    if (marketingAustralianTradeTrainingCollagePermission.trim() === "") {
      toast.error("Please indicate your marketing permission preference.");
      document.getElementById("marketingPermissionRadio").focus();
      return;
    }

    // After all the validation checks

    // Navigate to Education & Employment
    // navigate("/user/create_new_application/stage_1/education_employment", { state: { pointerData } });
    // toast.success("USI & Avetmiss Details saved successfully.");

    // Construct form data object
    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append("currently_have_usi", currentlyInAustralia ? "yes" : "no");
    formData.append("usi_no", currentlyInAustralia ? USI : "");
    formData.append(
      "have_usi_transcript",
      currentlyInAustralia ? USITranscript : ""
    );
    formData.append(
      "permission_access_usi_transcripts",
      currentlyInAustralia && USITranscript === "yes"
        ? attcPermission
          ? "yes"
          : "no"
        : ""
    );

    formData.append("speak_english_at_home", languageOtherThanEnglish);
    formData.append(
      "specify_language",
      languageOtherThanEnglish === "yes" ? specifyLanguage : ""
    );
    formData.append("proficiency_in_english", proficientInEnglish);
    formData.append("are_you_aboriginal", aboriginal);
    formData.append("choose_origin", aboriginal === "yes" ? originOption : "");
    formData.append("have_any_disability", disabilityOrimpairment);
    formData.append(
      "indicate_area",
      disabilityOrimpairment === "yes" ? indiacteArea : ""
    );
    formData.append(
      "require_additional_support",
      additionalSupportToParticipate
    );
    formData.append(
      "note",
      additionalSupportToParticipate === "yes" ? specifySupportYouNeed : ""
    );
    formData.append(
      "marketing",
      marketingAustralianTradeTrainingCollagePermission
    );

    // API call to save USI & Avetmiss details
    try {
      setLoading(true);

      const response = await createUSIandAvetmiss_stage1_API(formData);
      setLoading(false);

      if (response?.data?.response?.response === true) {
        navigate("/user/create_new_application/stage_1/education_employment", {
          state: { pointerData },
        });
        toast.success("USI & Avetmiss Details saved successfully.");
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save USI & Avetmiss Details"
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Error saving USI & Avetmiss Details:", error);
      toast.error("Failed to save USI & Avetmiss Details");
    }
  };

  const handleSaveAndExit = async () => {
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    const getUSIDetailsFunction = async () => {
      try {
        setLoading(true);
        const response = await getUSIandAvetmiss_stage1_API(
          pointerData?.pointer_id
        );
        setLoading(false);

        if (
          response &&
          response?.data &&
          response?.data?.response &&
          response?.data?.response?.data
        ) {
          const stage_1_USI_details =
            response?.data?.response?.data?.usi_details;

          if (
            stage_1_USI_details.currently_have_usi === "no"
            // || stage_1_USI_details.currently_have_usi === ""
          ) {
            setCurrentlyInAustralia(false);
          } else if (stage_1_USI_details.currently_have_usi === "yes") {
            setCurrentlyInAustralia(true);
          }

          if (
            stage_1_USI_details.permission_access_usi_transcripts === "no" ||
            stage_1_USI_details.permission_access_usi_transcripts === ""
          ) {
            setAttcPermission(false);
          } else if (
            stage_1_USI_details.permission_access_usi_transcripts === "yes"
          ) {
            setAttcPermission(true);
          }

          if (
            stage_1_USI_details.speak_english_at_home === "no"
            // || stage_1_USI_details.speak_english_at_home === ""
          ) {
            setLanguageOtherThanEnglish("no");
          } else if (
            stage_1_USI_details.speak_english_at_home === "yes" ||
            stage_1_USI_details.speak_english_at_home === ""
          ) {
            setLanguageOtherThanEnglish("yes");
          }

          setUSI(stage_1_USI_details.usi_no);
          setUSITranscript(stage_1_USI_details.have_usi_transcript);

          // setLanguageOtherThanEnglish(stage_1_USI_details.speak_english_at_home);

          setSpecifyLanguage(stage_1_USI_details.specify_language);
          setProficientInEnglish(stage_1_USI_details.proficiency_in_english);
          setAboriginal(stage_1_USI_details.are_you_aboriginal);
          setOriginOption(stage_1_USI_details.choose_origin);
          setDisabilityOrimpairment(stage_1_USI_details.have_any_disability);
          setIndiacateArea(stage_1_USI_details.indicate_area);
          setAdditionalSupportToParticipate(
            stage_1_USI_details.require_additional_support
          );
          setSpecifySupportYouNeed(stage_1_USI_details.note);
          setMarketingAustralianTradeTrainingCollagePermission(
            stage_1_USI_details.marketing
          );
        } else {
          setLoading(false);

          console.error(
            "Error fetching occupations data:",
            response?.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        setLoading(false);

        console.error("Error fetching occupations data:", error);
      }
    };

    if (pointerData?.pointer_id) {
      getUSIDetailsFunction(pointerData?.pointer_id);
    }
  }, [pointerData?.pointer_id]);

  return (
    <>
      <div className="forgot-container-new-USI">
        <Card className="shadow forgot-card-new-USI">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Unique Student Identifier (USI)</b>
          </div>
          <Card.Body>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 d-flex justify-content-end">
                <div className="mb-3 mt-1">
                  Portal Reference No. :{" "}
                  {/* <b style={{ color: "#055837" }}>24AQ063</b> */}
                  <b style={{ color: "#055837" }}>
                    {pointerData?.portal_refrance_no}
                  </b>
                </div>
              </div>
            </div>

            <Form>
              <div className="account-details">
                {currentlyInAustralia === true && (
                  <div className="row mb-3 bg-offwhite">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="currentlyInAustralia">
                        <Form.Label
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          ▸ I am currently in Australia?{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7 d-flex align-items-center">
                      <Form.Group
                        controlId="currentlyInAustraliaRadio"
                        className="d-flex"
                        style={{ cursor: "pointer" }}
                      >
                        <Form.Check
                          type="radio"
                          value="true"
                          label=""
                          id="currentlyInAustraliaRadio"
                          name="currentlyInAustralia"
                          className="me-5 radio-with-border"
                          style={{ cursor: "pointer" }}
                          checked={currentlyInAustralia === true}
                          onChange={() => setCurrentlyInAustralia(true)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}
                {currentlyInAustralia === true && (
                  <div className="row mb-3">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="usiLabel">
                        <Form.Label
                          htmlFor="usiInput"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          ▸ USI <span style={{ color: "red" }}>*</span>
                          <br />
                          <span className="light-highlighhhhhhhhhh-bg">
                            (
                            <span
                              style={{ fontWeight: "bold", color: "#055837" }}
                            >
                              Create USI
                            </span>{" "}
                            if you don’t have one)
                          </span>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7">
                      <Form.Control
                        id="usiInput"
                        type="text"
                        placeholder="Enter your USI"
                        value={USI}
                        onChange={(e) => setUSI(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {currentlyInAustralia === true && (
                  <div className="row mb-3 bg-offwhite">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="usiTranscriptQuestion">
                        <Form.Label
                          htmlFor="usiTranscriptInput"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          ▸ Do you have a USI Transcript?{" "}
                          <span style={{ color: "red" }}>*</span>
                          <br />
                          <span className="light-highlighhhhhhhhhh-bg">
                            (Kindly refer to the{" "}
                            <span
                              style={{ fontWeight: "bold", color: "#055837" }}
                            >
                              How to Guide
                            </span>{" "}
                            to download transcript)
                          </span>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7 d-flex align-items-center">
                      <Form.Group
                        controlId="usiTranscriptRadio"
                        className="d-flex"
                      >
                        <Form.Check
                          type="radio"
                          label="Yes"
                          value="yes"
                          id="usiTranscriptYes" // Set id here
                          name="usiTranscriptInput"
                          className="me-5 radio-with-border"
                          style={{ cursor: "pointer" }}
                          checked={USITranscript === "yes"}
                          onChange={(e) => setUSITranscript(e.target.value)}
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          value="no"
                          className="me-5 radio-with-border"
                          style={{ cursor: "pointer" }}
                          id="usiTranscriptNo"
                          name="usiTranscriptInput"
                          checked={USITranscript === "no"}
                          onChange={(e) => setUSITranscript(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}

                {currentlyInAustralia === true &&
                  (USITranscript === "yes" || USITranscript === "") && (
                    // {currentlyInAustralia && USITranscript === "yes" && (

                    <div className="row mb-3">
                      <div className="col-md-5 d-flex align-items-center justify-content-start">
                        <Form.Group controlId="attcPermissionQuestion">
                          <Form.Label
                            htmlFor="attcPermissionCheckbox"
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              paddingLeft: "10px",
                            }}
                          >
                            ▸ I have assigned ATTC permission to access USI
                            Transcripts. <span style={{ color: "red" }}>*</span>
                            <br />
                            <span className="light-highlighhhhhhhhhh-bg">
                              (Kindly refer to the{" "}
                              <span
                                style={{ fontWeight: "bold", color: "#055837" }}
                              >
                                How to Guide
                              </span>{" "}
                              to assign permission)
                            </span>
                          </Form.Label>
                        </Form.Group>
                      </div>
                      <div className="col-md-7 d-flex align-items-center">
                        <Form.Group
                          controlId="attcPermissionCheckbox"
                          className="d-flex"
                          style={{ cursor: "pointer" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="attcPermissionCheckbox"
                            checked={attcPermission}
                            onChange={(e) =>
                              setAttcPermission(e.target.checked)
                            }
                            style={{
                              transform: "scale(1.1)",
                              border: "1px solid black",
                              width: "1.1rem",
                              height: "1.1rem",
                              marginLeft: "0px",
                              cursor: "pointer",
                              // backgroundColor: attcPermission
                              //   ? "#055837"
                              //   : "none",
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  )}

                {currentlyInAustralia === true && USITranscript === "no" && (
                  <div
                    className="col-lg-12 bg-warning rounded p-2 mb-3"
                    style={{ fontSize: "16px", lineHeight: "1.5rem" }}
                  >
                    <b style={{ color: "#055837" }}>Note:</b> It is a mandatory
                    requirement to verify qualifications for any skills
                    assessment application. If the USI Transcripts are
                    unavailable, we will have to manually verify the
                    qualifications from the RTO which has awarded the
                    qualification.
                  </div>
                )}

                {currentlyInAustralia === false && (
                  <div className="row mb-4 bg-offwhite">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="outsideOfAustraliaOrOffshore">
                        <Form.Label
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          ▸ I am offshore (outside of Australia) - Do not need
                          any USI? <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7 d-flex align-items-center">
                      <Form.Group
                        controlId="outsideOfAustraliaOrOffshoreRadio"
                        className="d-flex"
                        style={{ cursor: "pointer" }}
                      >
                        <Form.Check
                          type="radio"
                          value="true"
                          label=""
                          id="outsideOfAustraliaOrOffshoreRadio"
                          name="currentlyInAustralia"
                          className="me-5 radio-with-border"
                          style={{ cursor: "pointer" }}
                          checked={currentlyInAustralia === false}
                          onChange={() => setCurrentlyInAustralia(false)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}

                <div>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Avetmiss Details</b>
                    </div>
                    <Card.Body>
                      <Form onSubmit={handleNext}>
                        <div className="row mb-3 bg-offwhite">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="languageOtherThanEnglishQuestion">
                              <Form.Label
                                htmlFor="languageOtherThanEnglishRadio"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ Do you speak a Language other than English at
                                home? <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7 d-flex align-items-center">
                            <Form.Group
                              controlId="languageOtherThanEnglishRadio"
                              className="d-flex"
                            >
                              <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                id="languageOtherThanEnglishYes"
                                name="languageOtherThanEnglishYes"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                checked={languageOtherThanEnglish === "yes"}
                                onChange={(e) =>
                                  setLanguageOtherThanEnglish(e.target.value)
                                }
                              />
                              <Form.Check
                                type="radio"
                                label="No"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                value="no"
                                id="languageOtherThanEnglishNo"
                                name="languageOtherThanEnglishNo"
                                checked={languageOtherThanEnglish === "no"}
                                onChange={(e) =>
                                  setLanguageOtherThanEnglish(e.target.value)
                                }
                              />
                            </Form.Group>
                          </div>

                          {languageOtherThanEnglish === "yes" && (
                            <div className="row mb-3">
                              <div className="col-md-5 d-flex align-items-center justify-content-start">
                                <Form.Group controlId="languageSpecification">
                                  <Form.Label
                                    htmlFor="languageSpecificationInput"
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "left",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    ▸ Specify Language{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Form.Label>
                                </Form.Group>
                              </div>
                              <div className="col-md-7">
                                <Form.Control
                                  type="text"
                                  id="languageSpecificationInput"
                                  placeholder="Enter the language spoken at home"
                                  value={specifyLanguage}
                                  onChange={(e) =>
                                    setSpecifyLanguage(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="row mb-3">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="englishProficiencyLabel">
                              <Form.Label
                                htmlFor="englishProficiencySelect"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "right",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ Proficiency in Spoken English{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7">
                            <Form.Group controlId="englishProficiencySelect">
                              <Form.Select
                                id="englishProficiencySelect"
                                value={proficientInEnglish}
                                onChange={(e) =>
                                  setProficientInEnglish(e.target.value)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <option
                                  className="dropdown-options"
                                  value=""
                                  disabled
                                  selected
                                >
                                  Select Proficiency
                                </option>
                                <option
                                  className="dropdown-options"
                                  value="Very Well"
                                >
                                  Very Well
                                </option>
                                <option
                                  className="dropdown-options"
                                  value="Well"
                                >
                                  Well
                                </option>
                                <option
                                  className="dropdown-options"
                                  value="Not Well"
                                >
                                  Not Well
                                </option>
                                <option
                                  className="dropdown-options"
                                  value="Not at All"
                                >
                                  Not at All
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        </div>

                        <div className="row mb-3 bg-offwhite">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="aboriginalOriginLabel">
                              <Form.Label
                                htmlFor="aboriginalOriginRadio"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ Are you of Aboriginal or Torres Strait
                                Islander Origin?{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7 d-flex align-items-center">
                            <Form.Group
                              controlId="aboriginalOriginRadio"
                              className="d-flex"
                            >
                              <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                id="aboriginalOriginRadioYes"
                                name="aboriginalOriginYes"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                checked={aboriginal === "yes"}
                                onChange={(e) => setAboriginal(e.target.value)}
                              />
                              <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                id="aboriginalOriginRadioNo"
                                name="aboriginalOriginNo"
                                checked={aboriginal === "no"}
                                onChange={(e) => setAboriginal(e.target.value)}
                              />
                            </Form.Group>
                          </div>

                          {aboriginal === "yes" && (
                            <>
                              <div className="col-md-5 mb-3 d-flex align-items-center justify-content-start">
                                <Form.Group controlId="chooseOriginOption">
                                  <Form.Label
                                    htmlFor="chooseOriginOption"
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "left",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    ▸ Choose origin option{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Form.Label>
                                </Form.Group>
                              </div>
                              <div className="col-md-7 d-flex align-items-center">
                                <Form.Group
                                  controlId="chooseOriginOption"
                                  className="d-flex"
                                >
                                  <Form.Check
                                    type="radio"
                                    label="Aboriginal"
                                    value="Aboriginal"
                                    id="chooseOriginOptionAboriginal"
                                    name="originOptionAboriginal"
                                    className="me-5 radio-with-border"
                                    style={{ cursor: "pointer" }}
                                    checked={originOption === "Aboriginal"}
                                    onChange={(e) =>
                                      setOriginOption(e.target.value)
                                    }
                                  />
                                  <Form.Check
                                    type="radio"
                                    label="Torres Strait Islander"
                                    value="Torres Strait Islander"
                                    id="chooseOriginOptionTorresStraitIslander"
                                    className="me-5 radio-with-border"
                                    style={{ cursor: "pointer" }}
                                    name="originOptionTorresStraitIslander"
                                    checked={
                                      originOption === "Torres Strait Islander"
                                    }
                                    onChange={(e) =>
                                      setOriginOption(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="row mb-3 bg-offwhite">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="disabilityOrImpairmentLabel">
                              <Form.Label
                                htmlFor="disabilityOrImpairment"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ Do you consider yourself to have a disability,
                                impairment or long-term condition ?{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7 d-flex align-items-center">
                            <Form.Group
                              controlId="disabilityOrImpairment"
                              className="d-flex"
                            >
                              <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                id="disabilityOrImpairmentYes"
                                name="disabilityOrImpairmentYes"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                checked={disabilityOrimpairment === "yes"}
                                onChange={(e) =>
                                  setDisabilityOrimpairment(e.target.value)
                                }
                              />
                              <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                id="disabilityOrImpairmentNo"
                                name="disabilityOrImpairmentNo"
                                checked={disabilityOrimpairment === "no"}
                                onChange={(e) =>
                                  setDisabilityOrimpairment(e.target.value)
                                }
                              />
                            </Form.Group>
                          </div>

                          {disabilityOrimpairment === "yes" && (
                            <div className="row mb-3">
                              <div className="col-md-5 d-flex align-items-center justify-content-start">
                                <Form.Group controlId="specifyArea">
                                  <Form.Label
                                    htmlFor="specifyAreaSelect"
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "left",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    ▸ Please indicate Area{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Form.Label>
                                </Form.Group>
                              </div>
                              <div className="col-md-7">
                                <Form.Select
                                  value={indiacteArea}
                                  onChange={(e) =>
                                    setIndiacateArea(e.target.value)
                                  }
                                  style={{ cursor: "pointer" }}
                                  id="specifyAreaSelect"
                                >
                                  <option
                                    className="dropdown-options"
                                    value=""
                                    disabled
                                    selected
                                  >
                                    Select area of disability
                                  </option>
                                  <option
                                    className="dropdown-options"
                                    value="Learning"
                                  >
                                    Learning Disability
                                  </option>
                                  <option
                                    className="dropdown-options"
                                    value="Hearing"
                                  >
                                    Hearing Impairment
                                  </option>
                                  <option
                                    className="dropdown-options"
                                    value="Physical"
                                  >
                                    Physical Disability
                                  </option>
                                  <option
                                    className="dropdown-options"
                                    value="Vision"
                                  >
                                    Vision Impairment
                                  </option>
                                  <option
                                    className="dropdown-options"
                                    value="MentalIllness"
                                  >
                                    Mental Illness
                                  </option>
                                  <option
                                    className="dropdown-options"
                                    value="MedicalCondition"
                                  >
                                    Medical Condition
                                  </option>
                                </Form.Select>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="row mb-2 bg-offwhite">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="additionalSupportQuestion">
                              <Form.Label
                                htmlFor="additionalSupportRadio"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                }}
                              >
                                ▸ Will you require additional support to
                                participate in this skills assessment?{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7 d-flex align-items-center">
                            <Form.Group
                              controlId="additionalSupportRadio"
                              className="d-flex"
                            >
                              <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                id="additionalSupportRadioYes"
                                name="additionalSupportToParticipateInputYes"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                checked={
                                  additionalSupportToParticipate === "yes"
                                }
                                onChange={(e) =>
                                  setAdditionalSupportToParticipate(
                                    e.target.value
                                  )
                                }
                              />
                              <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                id="additionalSupportRadioNo"
                                name="additionalSupportToParticipateInputNo"
                                checked={
                                  additionalSupportToParticipate === "no"
                                }
                                onChange={(e) =>
                                  setAdditionalSupportToParticipate(
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                          </div>

                          {additionalSupportToParticipate === "yes" && (
                            <div className="row mb-2">
                              <div className="col-md-5 d-flex align-items-center justify-content-start">
                                <Form.Group controlId="specifySupport">
                                  <Form.Label
                                    htmlFor="specifySupportInput"
                                    style={{
                                      fontWeight: "bold",
                                      textAlign: "left",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    ▸ Please specify the support you need{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Form.Label>
                                </Form.Group>
                              </div>
                              <div className="col-md-7">
                                <Form.Control
                                  type="text"
                                  placeholder="Enter the support you need"
                                  value={specifySupportYouNeed}
                                  onChange={(e) =>
                                    setSpecifySupportYouNeed(e.target.value)
                                  }
                                  id="specifySupportInput"
                                />
                              </div>
                              <div className="col-md-12">
                                <p style={{ marginTop: "15px" }}>
                                  (Note: Your Assessor will have a confidential
                                  discussion with you to determine if any
                                  reasonable adjustment of assessing is
                                  required.)
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>

                <div style={{ marginTop: "25px" }}>
                  <Card className="shadow">
                    <div
                      className="card-header text-center"
                      style={{ fontSize: "20px", color: "#055837" }}
                    >
                      <b>Marketing</b>
                    </div>
                    <Card.Body>
                      <Form onSubmit={handleNext}>
                        <div className="row mb-2 ">
                          <div className="col-md-5 d-flex align-items-center justify-content-start">
                            <Form.Group controlId="marketingPermission">
                              <Form.Label
                                htmlFor="marketingPermissionRadio"
                                style={{
                                  fontWeight: "bold",
                                  textAlign: "left",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                }}
                              >
                                ▸ I give Australian Trade Training College
                                permission to use photos/images in public
                                material and social media (including any
                                photos/images where I may be recognized or
                                participating in workplace activities) for
                                current and future marketing and business
                                purposes. I understand that I retain the right
                                to withdraw my consent at any time via email to
                                tra@attc.org.au.{" "}
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                            </Form.Group>
                          </div>
                          <div className="col-md-7 d-flex align-items-center">
                            <Form.Group
                              controlId="marketingPermissionRadio"
                              className="d-flex"
                            >
                              <Form.Check
                                type="radio"
                                label="Yes"
                                value="yes"
                                id="marketingPermissionRadioyes"
                                name="marketingPermission"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                checked={
                                  marketingAustralianTradeTrainingCollagePermission ===
                                  "yes"
                                }
                                onChange={(e) =>
                                  setMarketingAustralianTradeTrainingCollagePermission(
                                    e.target.value
                                  )
                                }
                              />
                              <Form.Check
                                type="radio"
                                label="No"
                                value="no"
                                id="marketingPermissionRadioNo"
                                name="marketingPermission"
                                className="me-5 radio-with-border"
                                style={{ cursor: "pointer" }}
                                checked={
                                  marketingAustralianTradeTrainingCollagePermission ===
                                  "no"
                                }
                                onChange={(e) =>
                                  setMarketingAustralianTradeTrainingCollagePermission(
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </div>

              {/* {loading && (
                <div className="d-flex justify-content-center mt-3">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )} */}

              {loading && <Loader />}

              {/* Back and Save & Exit and Next Buttons */}
              <div
                className="button-group text-center mb-1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <div
                  className="button-group text-center mb-1"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {/* Back Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onClick={handleBack}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Back
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveAndExit}
                    style={{
                      backgroundColor: "#055837",
                      color: "#ffcc01",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                  >
                    Save & Exit
                  </button>

                  {/* Next Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onClick={handleNext}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </Form>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Leaving"
            message="Are you sure you want to leave this page? You don't want to continue this new application process right now? If you proceed, you'll be redirected to the dashboard."
          />

          {backButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackModal}
              onHide={handleConfirmationBackNo}
              onConfirm={handleConfirmationBackYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the Identification form page?"
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default USIAndAvetmiss;
