import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import "./All Type Upload.css";
import { XCircleFill, CheckCircleFill } from "react-bootstrap-icons";
import ConfirmationModal from "../../../../Confirmation Modal/ConfirmationModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  upload_AdditionalRequest_API,
  deleteAdditionalDocumentAPI,
  getAdditionalRequestDocumentsDetailsAPI,
} from "../../../../../api";
import sampleImage from "../Sample Images/samPDF.jpeg";

const AllTypeUpload = ({
  show,
  onHide,
  pointerData,
  getUploadedDocsList,
  ModalHeading,
  ModalID,
  pointerIDDDDD,
  ActiveStageByClick,
  DocID,
  EmploymentId,
  ModalAdditionalUniqueID,
  get_ViewApplication_Function,
  allowedTypes,
  Reason
}) => {
  const [documentSections, setDocumentSections] = useState([
    {
      qualificationName: "",
      selectedFile: null,
      uploadSuccess: false,
      cancelClicked: false,
      selectedFileName: "",
      loading: false,
      doc_iddddd: null,
      file_URL: null,
      showConfirmationCancelModal: false,
    },
  ]);

  const modal_id = ModalID;

  useEffect(() => {
    if (show) {
      getDocumentDetailsFunction();
    }
  }, [show]);

  const getDocumentDetailsFunction = async () => {
    // Construct form data object
    const formDataGet = new FormData();
    formDataGet.append("pointer_id", pointerData?.pointer_id);
    // formDataGet.append("document_id", DocID);
    formDataGet.append("id", ModalAdditionalUniqueID);

    try {
      setLoading(0, true);
      const response = await getAdditionalRequestDocumentsDetailsAPI(
        formDataGet
      );
      setLoading(0, false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true
      ) {
        const documents = response?.data?.response?.data;
        get_ViewApplication_Function(pointerData?.pointer_id);

        let updatedSections = [];
        if (Array.isArray(documents)) {
          updatedSections = documents.map((doc) => ({
            doc_iddddd: doc.id,
            file_URL: doc.document_path,
            qualificationName: doc.name,
            uploadSuccess: true,
            selectedFile: true,
            selectedFileName: doc.document_name,
            loading: false,
            cancelClicked: false,
            showConfirmationCancelModal: false,
          }));
        } else if (documents && typeof documents === "object") {
          updatedSections = [
            {
              doc_iddddd: documents.id,
              file_URL: documents.document_path,
              qualificationName: documents.name,
              uploadSuccess: true,
              selectedFile: true,
              selectedFileName: documents.document_name,
              loading: false,
              cancelClicked: false,
              showConfirmationCancelModal: false,
            },
          ];
        }

        setDocumentSections(updatedSections);
        getUploadedDocsList();
      } else {
        console.error(
          "Error fetching document data:",
          response?.data?.response?.error_msg || "Unknown error"
        );
      }
    } catch (error) {
      setLoading(0, false);
      console.error("Error fetching document data:", error);
    }
  };

  // const handleFileSelect = (index, event) => {
  //   const file = event.target.files[0];
  //   updateSection(index, { selectedFile: file, selectedFileName: file.name });
  // };


  const handleFileSelect = (index, event) => {
    const file = event.target.files[0];
    const fileExtension = file?.name.split('.').pop().toLowerCase(); // Get file extension
  
    if (!allowedTypes.includes(fileExtension)) {
      // Show a toast error
      toast.error(`File type .${fileExtension} is not allowed. Only the following types are allowed: ${allowedTypes.join(", ")}`);
  
      // Clear the file input
      event.target.value = ""; // Reset the input
      return; // Prevent further execution
    }
  
    // If valid file type, update the section with file details
    updateSection(index, { selectedFile: file, selectedFileName: file.name });
  };

  

  const handleUpload = async (index) => {
    const section = documentSections[index];
    if (section.qualificationName.trim() === "") {
      toast.error("Please enter document name.");
      document.getElementById(`qualificationNameInput-${index}`).focus();
      return;
    }
    if (!section.selectedFile) {
      toast.error("Please select the file.");
      return;
    }

    // Construct form data object
    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append("file", section.selectedFile);
    formData.append("doc_name", section.qualificationName);
    formData.append("id", ModalAdditionalUniqueID);
    formData.append("stage", ActiveStageByClick);
    formData.append("document_id", DocID);
    formData.append("employee_id", EmploymentId);

    try {
      setLoading(index, true);

      const response = await upload_AdditionalRequest_API(formData);

      setLoading(index, false);

      if (response?.data?.response?.response === true) {
        updateSection(index, { uploadSuccess: true });
        getDocumentDetailsFunction();
        getUploadedDocsList();
        get_ViewApplication_Function(pointerData?.pointer_id);

        onHide();
        toast.success(
          response?.data?.response?.success_msg || "File Uploaded Successfully."
        );



      } else {
        toast.error(
          response?.data?.response?.error_msg || "Failed to upload file."
        );
      }
    } catch (error) {
      setLoading(index, false);
      console.error("Failed to upload file.", error);
      toast.error("Failed to upload file.");
    }
  };

  const setLoading = (index, loading) => {
    updateSection(index, { loading });
  };

  const updateSection = (index, updatedValues) => {
    setDocumentSections((prevSections) => {
      const newSections = [...prevSections];
      newSections[index] = { ...newSections[index], ...updatedValues };
      return newSections;
    });
  };

  const addNewDocumentSection = () => {
    setDocumentSections((prevSections) => [
      ...prevSections,
      {
        qualificationName: "",
        selectedFile: null,
        uploadSuccess: false,
        cancelClicked: false,
        selectedFileName: "",
        loading: false,
        doc_iddddd: null,
        file_URL: null,
        showConfirmationCancelModal: false,
      },
    ]);
  };

  const handleConfirmationBackNo = (index) => {
    updateSection(index, { showConfirmationCancelModal: false });
  };

  const handleConfirmationBackYes = async (index) => {
    const section = documentSections[index];

    // Construct form data object
    const formDataDelete = new FormData();
    formDataDelete.append("pointer_id", pointerData?.pointer_id);
    formDataDelete.append("id", ModalAdditionalUniqueID);
    formDataDelete.append("document_id", section.doc_iddddd);
    try {
      setLoading(index, true);

      const response = await deleteAdditionalDocumentAPI(formDataDelete);

      setLoading(index, false);

      if (response?.data?.response?.response === true) {
        toast.success("File has been deleted successfully.");
        updateSection(index, {
          selectedFile: null,
          qualificationName: "",
          uploadSuccess: false,
          cancelClicked: false,
          doc_iddddd: null,
          file_URL: null,
        });
        getUploadedDocsList();
        getDocumentDetailsFunction();
        get_ViewApplication_Function(pointerData?.pointer_id);
      } else {
        toast.error(
          response?.data?.response?.error_msg || "Failed to delete file."
        );
      }
    } catch (error) {
      setLoading(index, false);
      console.error("Failed to delete file.", error);
      toast.error("Failed to delete file.");
    }
  };

  const handleConfirmationModal = (index) => {
    updateSection(index, {
      cancelClicked: true,
      showConfirmationCancelModal: true,
    });
    getUploadedDocsList();
  };

  const canAddNewSection = documentSections.every(
    (section) => section.uploadSuccess
  );

  const renderFilePreview = (section) => {
    const fileExtension = section?.selectedFileName
      .split(".")
      .pop()
      .toLowerCase();
    if (fileExtension === "xlsx" || fileExtension === "xls") {
      return (
        <div>
          <a href={section.file_URL} target="_blank" rel="noopener noreferrer">
            {`Open ${fileExtension.toUpperCase()} File`}
          </a>
        </div>
      );
    } else if (fileExtension === "pdf") {
      return (
        <iframe
          src={section?.file_URL}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            overflow: "auto",
          }}
          title="Uploaded File Preview"
        />
      );
    } else if (fileExtension === "mp4" || fileExtension === "mkv") {
      return (
        <video
          controls
          style={{ width: "100%", height: "100%" }}
          src={section.file_URL}
          title="Uploaded Video Preview"
        >
          Your browser does not support the video tag.
        </video>
      );
    } else if (fileExtension === "mp3") {
      return (
        <audio
          controls
          style={{ width: "100%", height: "30px" }}
          src={section.file_URL}
          title="Uploaded Audio Preview"
        >
          Your browser does not support the audio tag.
        </audio>
      );
    }  else {
      return (
        <iframe
          src={section?.file_URL}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            overflow: "auto",
          }}
          title="Uploaded File Preview"
        />
      );
    }
  };


  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title
          className="text-center"
          style={{
            fontSize: "18px",
            fontFamily: "Arial, sans-serif",
            color: "#055837",
          }}
        >
          {/* {ModalHeading} */}
          Additional Info
           {/* <span style={{ color: "red" }}>*</span> */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>


      {
          <div
            className="bg-warning rounded p-2 text-size-control mb-2"
            style={{ margin: "3px" }}
          >
            <h5 className="mb-2">Comment :</h5>
            <ul>
              <li>{ModalHeading}</li>
            </ul>
          </div>
        }



        {documentSections.map((section, index) => (
          <div key={index}>
            {/* <h5 className="text-center">Document {index + 1}</h5> */}

            {!section.uploadSuccess ? (
              <div className="row">
                <div className="col-7">
                  <div className="row mb-3 mt-2 bg-offwhite">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId={`qualificationNameLabel-${index}`}>
                        <Form.Label
                          htmlFor={`qualificationNameInput-${index}`}
                          style={{
                            textAlign: "left",
                            paddingLeft: "10px",
                            fontSize: "15px",
                          }}
                        >
                          ▸ Document Name{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7">
                      <Form.Control
                        type="text"
                        id={`qualificationNameInput-${index}`}
                        placeholder="Enter your document name"
                        value={section.qualificationName}
                        onChange={(e) =>
                          updateSection(index, {
                            qualificationName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <Form.Group controlId={`formFile-${index}`}>
                    <Form.Label
                      className="d-flex align-items-center"
                      style={{
                        fontSize: "15px",
                        marginBottom: "5px",
                        textAlign: "left",
                      }}
                    >
                      <div style={{ display: "inline-block" }}>
                        <i className="fas fa-file-upload me-1"></i>
                        <span className="text-nowrap">
                          Select a File <span style={{ color: "red" }}>*</span>
                        </span>
                      </div>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => handleFileSelect(index, e)}
                      accept={allowedTypes.map((type) => `.${type}`).join(",")}
                      style={{ height: "35px" }}
                    />
    

                    <div className="text-danger text-size-control-extensions">
                      Only:{" "}
                      {allowedTypes.map((type, index) => (
                        <React.Fragment key={index}>
                          .{type}
                          {index !== allowedTypes.length - 1 && ", "}
                        </React.Fragment>
                      ))}
                    </div>
                  </Form.Group>
                </div>

                <div className="col-5 mt-1 d-flex align-items-center justify-content-center text-size-control">
                  {section.cancelClicked && !section.selectedFile && (
                    <div>
                      <span>▸ File Deleted Successfully !</span>
                      <br></br>
                      <span>▸ Please choose another file.</span>
                    </div>
                  )}

                  {!section.selectedFile &&
                    section.file_URL === null &&
                    !section.cancelClicked && (
                      <div className="text-center text-size-control">
                        <p>Sample File Preview:</p>
                        <iframe
                          src={sampleImage}
                          style={{
                            width: "100%",
                            height: "100%",
                            border: "none",
                            overflow: "auto",
                          }}
                          title="Sample File Preview:"
                        />
                      </div>
                    )}

                  {/* {section.selectedFile && (
                    <div className="text-center text-size-control">
                      <p>Selected File Preview:</p>
                      <div style={{ width: "100%", height: "100%" }}>
                        <iframe
                          src={
                            section.file_URL !== null
                              ? section.file_URL
                              : URL.createObjectURL(section.selectedFile)
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            border: "none",
                            overflow: "auto",
                          }}
                          title="Selected Preview"
                        />
                      </div>
                    </div>
                  )} */}








{section.selectedFile && (
                    <div className="text-center text-size-control">
                      <p>Selected File Preview:</p>
                      <div style={{ width: "100%", height:"150px" ,position: "relative" }}>
                        {section.selectedFile.type.startsWith("image/") ? (
                          <img
                            src={URL.createObjectURL(section.selectedFile)} // Create an object URL for the image
                            alt="Selected Preview"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              border: "1px solid #ccc", // Border for visibility
                            }}
                          />
                        ) : section.selectedFile.type === "application/pdf" ? (
                          <iframe
                            src={URL.createObjectURL(section.selectedFile)} // Create an object URL for the PDF
                            style={{
                              width: "100%",
                              height: "100%",
                              border: "none",
                              overflow: "auto",
                            }}
                            title="Selected Preview"
                          />
                        ) : section.selectedFile.type.startsWith(
                            "application/vnd.openxmlformats-officedocument"
                          ) || // Check for Excel files
                          section.selectedFile.type.startsWith(
                            "application/vnd.ms-excel"
                          ) ? (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <p>Preview not available for Excel files.</p>
                            <a
                              href={`https://docs.google.com/gview?url=${URL.createObjectURL(
                                section.selectedFile
                              )}&embedded=true`} // Provide link to Google Docs viewer
                              target="_blank" // Open in a new tab
                              rel="noopener noreferrer"
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                              }}
                            >
                              Click here to view the Excel file.
                            </a>
                          </div>
                        ) : (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <p>Preview not available for this file type.</p>
                            <a
                              href={URL.createObjectURL(section.selectedFile)} // Provide link to download/view the file
                              target="_blank" // Open in a new tab
                              rel="noopener noreferrer"
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                              }}
                            >
                              Click here to download the file.
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  )}















                </div>

                <div className="text-center mt-3">
                  <Button
                    variant="light"
                    onClick={() => handleUpload(index)}
                    className="mx-auto"
                    style={{
                      maxWidth: "130px",
                      backgroundColor: "#055837",
                      color: "#ffcc01",
                      transition: "transform 0.1s",
                    }}
                    onMouseEnter={(e) =>
                      (e.target.style.transform = "scale(1.02)")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.transform = "scale(1)")
                    }
                  >
                    Upload <i className="bi bi-upload"></i>
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-7 mt-5">
                    <p
                      style={{ color: "#055837", marginBottom: "5px" }}
                      className="p-1 text-size-control-extensions"
                    >
                      File Uploaded successfully{" "}
                      <CheckCircleFill className="me-1" />
                    </p>

                    <div className="progress" style={{ height: "15px" }}>
                      <div
                        className="progress-bar d-flex align-items-center justify-content-center"
                        role="progressbar"
                        style={{ width: "100%" }}
                        aria-valuemax="100"
                      >
                        100%
                      </div>
                    </div>

                    <p className="mb-0 mt-1 me-2 text-size-control-extensions">
                      Document Name : {section.qualificationName}
                    </p>

                    <div className="d-flex align-items-center mt-2">
                      <p className="mb-0 mt-1 me-2 text-size-control-extensions">
                        Do you want to remove selected file ?
                      </p>
                      <div>
                        <Button
                          variant="link"
                          onClick={() => handleConfirmationModal(index)}
                          className="text-danger p-0"
                          style={{ marginLeft: "7px" }}
                        >
                          <div className="square-button">Yes</div>
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="col-5  mt-1 d-flex align-items-center justify-content-center">
                    {section.selectedFile && (
                      <div className="text-center text-size-control">
                        <p>Uploaded File Preview:</p>
                        <div style={{ width: "100%", height: "100%" }}>
                          {renderFilePreview(section)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {/* <hr className="mb-2 mt-1 thick-hr" /> */}
          </div>
        ))}

        {/* <div
          className={`text-center mt-4 ${
            canAddNewSection ? "cursor-pointer" : "cursor-not-allowed"
          }`}
          onClick={canAddNewSection ? addNewDocumentSection : null}
          style={{
            cursor: canAddNewSection ? "pointer" : "not-allowed",
          }}
        >
            <span
            style={{
              fontSize: "1.3rem",
              // color: canAddNewSection ? "#055837" : "#d3d3d3",
              backgroundColor: "#055837",
              width:"130px",
              color: "#ffcc01",
              padding:"6px",
              borderRadius:"5px",
              
            }}
          >
            Add <i className="fas fa-plus-circle"></i>
          </span>
        </div> */}
      </Modal.Body>

      {documentSections.some((section) => section.loading) && (
        <div className="d-flex justify-content-center mt-3">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}

      <Modal.Footer className="d-flex justify-content-center">
        <Button
          variant="light"
          onClick={onHide}
          className="w-50"
          style={{
            maxWidth: "130px",
            backgroundColor: "#dc3545",
            color: "white",
            transition: "transform 0.1s",
          }}
          onMouseEnter={(e) => (e.target.style.transform = "scale(1.02)")}
          onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
        >
          Close
        </Button>
      </Modal.Footer>

      {documentSections.map(
        (section, index) =>
          section.cancelClicked && (
            <ConfirmationModal
              key={index}
              show={section.showConfirmationCancelModal}
              onHide={() => handleConfirmationBackNo(index)}
              onConfirm={() => handleConfirmationBackYes(index)}
              title="Confirm Delete"
              message="Are you sure you want to remove this file?"
            />
          )
      )}
    </Modal>
  );
};

export default AllTypeUpload;
