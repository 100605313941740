import React, { useState, useEffect } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import "./Personal Details.css";
import ConfirmationModal from "../../../Confirmation Modal/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InputGroup } from "react-bootstrap";
import { BsCalendar } from "react-icons/bs";

import {
  createPersonalDetails_stage1_API,
  getPersonalDetails_stage1_API,
} from "../../../../api";
import { useLocation } from "react-router-dom";
import Loader from "../../../Loader/Loader";

const PersonalDetails = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const pointerData = location.state?.pointerData;

  const [loading, setLoading] = useState(false);

  const [preferredTitle, setPreferredTitle] = useState("Mr.");
  const [surname, setSurname] = useState("");
  const [previousSurname, setPreviousSurname] = useState("");
  const [singleName, setSingleName] = useState(false);
  const [onlyHadOneName, setOnlyHadOneName] = useState(false);
  const [firstOrGivenName, setFirstOrGivenName] = useState("");
  const [previousFirstOrGivenName, setPreviousFirstOrGivenName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [previousMiddleName, setPreviousMiddleName] = useState("");
  const [anyOtherName, setAnyOtherName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmationBackModal, setShowConfirmationBackModal] =
    useState(false);
  const [backButtonClicked, setBackButtonClicked] = useState(false);
  const handleConfirmationBackNo = () => {
    setShowConfirmationBackModal(false);
  };
  const handleConfirmationBackYes = () => {
    navigate("/user/create_new_application/stage_1/occupation_details", {
      state: { pointerData },
    });

    setShowConfirmationBackModal(false);
    toast.success("Navigated to Occupation Details form page.");
  };

  const handleBack = () => {
    // setBackButtonClicked(true);
    // setShowConfirmationBackModal(true);

    navigate("/user/create_new_application/stage_1/occupation_details", {
      state: { pointerData },
    });
    toast.success("Navigated to Occupation Details form page.");
  };

  const handleNext = async () => {


    // const formattedDateOfBirth =
    //   dateOfBirth &&
    //   `${dateOfBirth.getDate()}/${
    //     dateOfBirth.getMonth() + 1
    //   }/${dateOfBirth.getFullYear()}`;



    const formattedDateOfBirth =
  dateOfBirth
    ? new Date(dateOfBirth).getDate().toString().padStart(2, '0') + "/" +
      (new Date(dateOfBirth).getMonth() + 1).toString().padStart(2, '0') + "/" +
      new Date(dateOfBirth).getFullYear()
    : "";


    if (preferredTitle.trim() === "") {
      toast.error("Please select a preferred title.");
      document.getElementById("preferredTitleSelect").focus();
      return;
    }

    if (!singleName) {
      if (surname.trim() === "") {
        toast.error("Please enter your surname or family name.");
        document.getElementById("surnameInput").focus();
        return;
      }
      if (firstOrGivenName.trim() === "") {
        toast.error("Please enter your first or given name.");
        document.getElementById("firstOrGivenNameInput").focus();

        return;
      }


      // if (middleName.trim() === "") {
      //   toast.error("Please enter your middle name.");
      //   document.getElementById("middleNameInput").focus();

      //   return;
      // }


    }

    // Check if singleName checkbox is checked
    if (singleName) {
      // Check if firstOrGivenName is entered
      if (firstOrGivenName.trim() === "") {
        toast.error("Please enter your first or given name.");
        document.getElementById("firstOrGivenNameInput").focus();

        return;
      }
    }

    // Check if anyOtherName radio button is selected
    if (anyOtherName.trim() === "") {
      toast.error("Please select whether you are known by any other name.");
      document.getElementById("anyOtherNameRadio").focus();

      return;
    }

    // Check if anyOtherName radio button is selected
    if (anyOtherName === "yes" && !onlyHadOneName) {
      if (previousSurname.trim() === "") {
        toast.error("Please enter your previous surname or family name.");
        document.getElementById("previousSurnameInput").focus();

        return;
      }

      if (previousFirstOrGivenName.trim() === "") {
        toast.error("Please enter your previous first or given name.");
        document.getElementById("previousFirstOrGivenNameInput").focus();

        return;
      }

      // if (previousMiddleName.trim() === "") {
      //   toast.error("Please enter your previous middle name.");
      //   document.getElementById("previousMiddleNameInput").focus();

      //   return;
      // }


    }

    // Check if anyOtherName radio button is selected
    if (anyOtherName === "yes" && onlyHadOneName) {
      // Check if previousFirstOrGivenName is entered
      if (previousFirstOrGivenName.trim() === "") {
        toast.error("Please enter your previous first or given name.");
        document.getElementById("previousFirstOrGivenNameInput").focus();

        return;
      }
    }

    // Check if gender is selected
    if (gender.trim() === "") {
      toast.error("Please select your gender.");
      document.getElementById("genderRadio").focus();

      return;
    }

    // Check if dateOfBirth is selected
    if (dateOfBirth === null) {
      toast.error("Please select your date of birth.");
      document.getElementById("dateOfBirth").focus();

      return;
    }

    // // Navigate to dashboard
    // navigate("/user/create_new_application/stage_1/contact_details", { state: { pointerData } });
    // toast.success("Personal details saved successfully.");

    // Construct form data object
    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append("preferred_title", preferredTitle);
    formData.append("first_or_given_name", firstOrGivenName);
    formData.append("other_name", singleName ? "yes" : "no");
    formData.append("middle_names", singleName ? "" : middleName);
    formData.append("surname_family_name", singleName ? "" : surname);
    formData.append("known_by_any_name", anyOtherName);
    formData.append("checkbox_only_had_name", onlyHadOneName ? "yes" : "no");
    formData.append(
      "previous_names",
      anyOtherName === "yes" ? previousFirstOrGivenName : ""
    );
    formData.append(
      "previous_surname_or_family_name",
      anyOtherName === "yes" && !onlyHadOneName ? previousSurname : ""
    );
    formData.append(
      "previous_middle_names",
      anyOtherName === "yes" && !onlyHadOneName ? previousMiddleName : ""
    );
    formData.append("gender", gender);
    formData.append("date_of_birth", formattedDateOfBirth);

    // API call to save personal details
    try {
      setLoading(true);

      const response = await createPersonalDetails_stage1_API(formData);
      setLoading(false);

      if (response?.data?.response?.response === true) {
        const applicant_name = (
          (response?.data?.response?.data?.first_or_given_name
            ? response?.data?.response?.data?.first_or_given_name + " "
            : "") +
          (response?.data?.response?.data?.middle_names
            ? response?.data?.response?.data?.middle_names + " "
            : "") +
          (response?.data?.response?.data?.surname_family_name
            ? response?.data?.response?.data?.surname_family_name
            : "")
        ).trim();

        // const applicant_name = `${response?.data?.response?.data?.first_or_given_name || ''} ${response?.data?.response?.data?.middle_names || ''} ${response?.data?.response?.data?.surname_family_name || ''}`.trim();

        localStorage.setItem("active_candidate_name", applicant_name);

        navigate("/user/create_new_application/stage_1/contact_details", {
          state: { pointerData },
        });
        toast.success("Personal details saved successfully.");
      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Personal Details"
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Error saving Personal Details:", error);
      toast.error("Failed to save Personal Details");
    }
  };

  const handleConfirmationNo = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmationYes = async() => {

    // navigate("/user/dashboard");
    // setShowConfirmationModal(false);
    // // toast.success("Personal details saved successfully.");




    // const formattedDateOfBirth =
    //   dateOfBirth &&
    //   `${dateOfBirth.getDate()}/${
    //     dateOfBirth.getMonth() + 1
    //   }/${dateOfBirth.getFullYear()}`;



    const formattedDateOfBirth =
  dateOfBirth
    ? new Date(dateOfBirth).getDate().toString().padStart(2, '0') + "/" +
      (new Date(dateOfBirth).getMonth() + 1).toString().padStart(2, '0') + "/" +
      new Date(dateOfBirth).getFullYear()
    : "";



    const formData = new FormData();
    formData.append("pointer_id", pointerData?.pointer_id);
    formData.append("preferred_title", preferredTitle);
    formData.append("first_or_given_name", firstOrGivenName);
    formData.append("other_name", singleName ? "yes" : "no");
    formData.append("middle_names", singleName ? "" : middleName);
    formData.append("surname_family_name", singleName ? "" : surname);
    formData.append("known_by_any_name", anyOtherName);
    formData.append("checkbox_only_had_name", onlyHadOneName ? "yes" : "no");
    formData.append(
      "previous_names",
      anyOtherName === "yes" ? previousFirstOrGivenName : ""
    );
    formData.append(
      "previous_surname_or_family_name",
      anyOtherName === "yes" && !onlyHadOneName ? previousSurname : ""
    );
    formData.append(
      "previous_middle_names",
      anyOtherName === "yes" && !onlyHadOneName ? previousMiddleName : ""
    );
    formData.append("gender", gender);
    formData.append("date_of_birth", formattedDateOfBirth);

    // API call to save personal details
    try {
      setLoading(true);

      const response = await createPersonalDetails_stage1_API(formData);
      setLoading(false);

      if (response?.data?.response?.response === true) {
        const applicant_name = (
          (response?.data?.response?.data?.first_or_given_name
            ? response?.data?.response?.data?.first_or_given_name + " "
            : "") +
          (response?.data?.response?.data?.middle_names
            ? response?.data?.response?.data?.middle_names + " "
            : "") +
          (response?.data?.response?.data?.surname_family_name
            ? response?.data?.response?.data?.surname_family_name
            : "")
        ).trim();

        // const applicant_name = `${response?.data?.response?.data?.first_or_given_name || ''} ${response?.data?.response?.data?.middle_names || ''} ${response?.data?.response?.data?.surname_family_name || ''}`.trim();

        localStorage.setItem("active_candidate_name", applicant_name);

        navigate("/user/dashboard");
        setShowConfirmationModal(false);
        toast.success("Personal details saved successfully.");


      } else {
        // If API call fails, display error message
        toast.error(
          response?.data?.response?.error_msg ||
            "Failed to save Personal Details"
        );
      }
    } catch (error) {
      setLoading(false);

      console.error("Error saving Personal Details:", error);
      toast.error("Failed to save Personal Details");
    }




  };

  const handleSaveAndExit = async () => {
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    const getPersonalDetailsFunction = async () => {
      try {
        setLoading(true);
        const response = await getPersonalDetails_stage1_API(
          pointerData?.pointer_id
        );
        setLoading(false);

        if (
          response &&
          response?.data &&
          response?.data?.response &&
          response?.data?.response?.data &&
          response?.data?.response?.data?.personal_details
        ) {
          const stage_1_personalDetails =
            response?.data?.response?.data?.personal_details;

    
          if (stage_1_personalDetails?.date_of_birth === "" || stage_1_personalDetails?.date_of_birth === null || stage_1_personalDetails?.date_of_birth === undefined || stage_1_personalDetails?.date_of_birth === "undefined") {
            setDateOfBirth(null);
          } else {
            setDateOfBirth(stage_1_personalDetails?.date_of_birth);
          }

          if (stage_1_personalDetails.preferred_title === "") {
            setPreferredTitle("Mr.");
          } else {
            setPreferredTitle(stage_1_personalDetails.preferred_title);
          }

          if (
            stage_1_personalDetails.other_name === "no" ||
            stage_1_personalDetails.other_name === ""
          ) {
            setSingleName(false);
          } else if (stage_1_personalDetails.other_name === "yes") {
            setSingleName(true);
          }

          if (
            stage_1_personalDetails.checkbox_only_had_name === "no" ||
            stage_1_personalDetails.checkbox_only_had_name === ""
          ) {
            setOnlyHadOneName(false);
          } else if (stage_1_personalDetails.checkbox_only_had_name === "yes") {
            setOnlyHadOneName(true);
          }

          if (
            stage_1_personalDetails.middle_names === null ||
            stage_1_personalDetails.middle_names === ""
          ) {
            setMiddleName("");
          } else {
            setMiddleName(stage_1_personalDetails.middle_names);
          }

          setFirstOrGivenName(stage_1_personalDetails.first_or_given_name);
          setSurname(stage_1_personalDetails.surname_family_name);
          setAnyOtherName(stage_1_personalDetails.known_by_any_name);
          setPreviousFirstOrGivenName(stage_1_personalDetails.previous_names);
          setPreviousMiddleName(stage_1_personalDetails.previous_middle_names);
          setPreviousSurname(
            stage_1_personalDetails.previous_surname_or_family_name
          );
          setGender(stage_1_personalDetails.gender);
        } else {
          setLoading(false);

          console.error(
            "Error fetching occupations data:",
            response?.data?.error_msg || "Unknown error"
          );
        }
      } catch (error) {
        setLoading(false);

        console.error("Error fetching occupations data:", error);
      }
    };

    if (pointerData?.pointer_id) {
      getPersonalDetailsFunction(pointerData?.pointer_id);
    }
  }, [pointerData?.pointer_id]);

  return (
    <>
      <div className="forgot-container-personal-details">
        <Card className="shadow forgot-card-personal-details">
          <div
            className="card-header text-center"
            style={{ fontSize: "20px", color: "#055837" }}
          >
            <b>Personal details ( As Per Passport )</b>
          </div>
          <Card.Body>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 d-flex justify-content-end">
                <div className="mb-3 mt-1">
                  Portal Reference No. :{" "}
                  {/* <b style={{ color: "#055837" }}>24AQ063</b> */}
                  <b style={{ color: "#055837" }}>
                    {pointerData?.portal_refrance_no}
                  </b>
                </div>
              </div>
            </div>

            <Form>
              <div className="account-details">
                <div className="row mb-3 mt-1  bg-offwhite">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="preferredTitleLabel">
                      <Form.Label
                        htmlFor="preferredTitleSelect"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        ▸ Preferred Title{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7">
                    <Form.Group controlId="preferredTitleSelect">
                      <Form.Select
                        id="preferredTitleSelect"
                        value={preferredTitle}
                        onChange={(e) => setPreferredTitle(e.target.value)}
                        style={{ cursor: "pointer" }}
                      >
                        <option
                          className="dropdown-options"
                          value=""
                          disabled
                          defaultValue
                        >
                          Select Title
                        </option>
                        <option className="dropdown-options" value="Mr.">
                          Mr.
                        </option>
                        <option className="dropdown-options" value="Mrs.">
                          Mrs.
                        </option>
                        <option className="dropdown-options" value="Miss">
                          Miss
                        </option>
                        <option className="dropdown-options" value="Ms.">
                          Ms.
                        </option>
                        <option className="dropdown-options" value="Others">
                          Others
                        </option>
                        {/* Add more preferredTitle options here */}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>

                {singleName === false && (
                  <div className="row mb-3">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="surnameLabel">
                        <Form.Label
                          htmlFor="surnameInput"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                            fontSize: "15px",
                          }}
                        >
                          ▸ Surname / Family Name{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7">
                      <Form.Control
                        type="text"
                        id="surnameInput"
                        placeholder="Enter your surname or family name"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                <div className="row mb-3  bg-offwhite">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="singleNameLabel">
                      <Form.Label
                        htmlFor="singleNameCheckbox"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        ▸ I only have a single Name ?{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>

                  <div className="col-md-7 d-flex align-items-center">
                    <Form.Group
                      controlId="singleNameCheckbox"
                      className="d-flex align-items-center"
                    >
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="singleNameCheckbox"
                          name="singleNameCheckbox"
                          checked={singleName}
                          onChange={(e) => setSingleName(e.target.checked)}
                          style={{
                            transform: "scale(1.1)",
                            border: "1px solid black",
                            // color: "#055837",
                            // backgroundColor: singleName ? "#055837" : "",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="firstOrGivenNameLabel">
                      <Form.Label
                        htmlFor="firstOrGivenNameInput"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                          fontSize: "15px",
                        }}
                      >
                        ▸ First or Given Name{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7">
                    <Form.Control
                      type="text"
                      id="firstOrGivenNameInput"
                      placeholder="Enter your first or given name"
                      value={firstOrGivenName}
                      onChange={(e) => setFirstOrGivenName(e.target.value)}
                    />
                  </div>
                </div>

                {singleName === false && (
                  <div className="row mb-3 bg-offwhite">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="middleNameLabel">
                        <Form.Label
                          htmlFor="middleNameInput"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                            fontSize: "15px",
                          }}
                        >
                          ▸ Middle Name 
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7">
                      <Form.Control
                        type="text"
                        id="middleNameInput"
                        placeholder="Enter your middle name"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                <div className="row mb-3">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="anyOtherNameLabel">
                      <Form.Label
                        htmlFor="anyOtherNameRadio"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        ▸ Are you known by any other Name ?{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7 d-flex align-items-center">
                    <Form.Group
                      controlId="anyOtherNameRadio"
                      className="d-flex"
                    >
                      <Form.Check
                        type="radio"
                        label="Yes"
                        value="yes"
                        id="anyOtherNameRadioYes"
                        name="anyOtherName"
                        className="me-5 radio-with-border"
                        checked={anyOtherName === "yes"}
                        onChange={(e) => setAnyOtherName(e.target.value)}
                        style={{ cursor: "pointer" }}
                      />
                      <Form.Check
                        type="radio"
                        label="No"
                        value="no"
                        id="anyOtherNameRadioNo"
                        name="anyOtherName"
                        className="me-5 radio-with-border"
                        checked={anyOtherName === "no"}
                        onChange={(e) => setAnyOtherName(e.target.value)}
                        style={{ cursor: "pointer" }}
                      />
                    </Form.Group>
                  </div>
                </div>

                {anyOtherName === "yes" && onlyHadOneName === false && (
                  <div className="row mb-3 bg-offwhite">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="previousSurnameLabel">
                        <Form.Label
                          htmlFor="previousSurnameInput"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                            fontSize: "15px",
                          }}
                        >
                          ▸ Previous Surname / Family Name{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7">
                      <Form.Control
                        type="text"
                        id="previousSurnameInput"
                        placeholder="Enter your previous surname or family name"
                        value={previousSurname}
                        onChange={(e) => setPreviousSurname(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {anyOtherName === "yes" && (
                  <div className="row mb-3">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="onlyOneNameLabel">
                        <Form.Label
                          htmlFor="onlyOneNameCheckbox"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                          }}
                        >
                          ▸ I only had one Name ?{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                      </Form.Group>
                    </div>

                    <div className="col-md-7 d-flex align-items-center">
                      <Form.Group
                        controlId="onlyOneNameCheckbox"
                        className="d-flex align-items-center"
                      >
                        <div
                          style={{
                            width: "30px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="onlyOneNameCheckbox"
                            checked={onlyHadOneName}
                            onChange={(e) =>
                              setOnlyHadOneName(e.target.checked)
                            }
                            style={{
                              transform: "scale(1.1)",
                              border: "1px solid black",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                )}

                {anyOtherName === "yes" && (
                  <div className="row mb-3 bg-offwhite">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="previousFirstOrGivenName">
                        <Form.Label
                          htmlFor="previousFirstOrGivenNameInput"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                            fontSize: "15px",
                          }}
                        >
                          ▸ Previous First or Given Name{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7">
                      <Form.Control
                        type="text"
                        id="previousFirstOrGivenNameInput"
                        placeholder="Enter your previous first or given name"
                        value={previousFirstOrGivenName}
                        onChange={(e) =>
                          setPreviousFirstOrGivenName(e.target.value)
                        }
                      />
                    </div>
                  </div>
                )}

                {anyOtherName === "yes" && onlyHadOneName === false && (
                  <div className="row mb-3">
                    <div className="col-md-5 d-flex align-items-center justify-content-start">
                      <Form.Group controlId="previousMiddleName">
                        <Form.Label
                          htmlFor="previousMiddleNameInput"
                          style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            paddingLeft: "10px",
                            fontSize: "15px",
                          }}
                        >
                          ▸ Previous Middle Name{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </Form.Label>
                      </Form.Group>
                    </div>
                    <div className="col-md-7">
                      <Form.Control
                        type="text"
                        id="previousMiddleNameInput"
                        placeholder="Enter your previous middle name"
                        value={previousMiddleName}
                        onChange={(e) => setPreviousMiddleName(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                <div className="row mb-3 bg-offwhite">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="genderSelect">
                      <Form.Label
                        htmlFor="genderRadio"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        ▸ Gender <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7 d-flex align-items-center">
                    <Form.Group controlId="genderRadio" className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Male"
                        value="Male"
                        id="genderRadioMale"
                        name="gender"
                        className="me-5 radio-with-border"
                        checked={gender === "Male"}
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Female"
                        value="Female"
                        id="genderRadioFemale"
                        name="gender"
                        className="me-5 radio-with-border"
                        checked={gender === "Female"}
                        onChange={(e) => setGender(e.target.value)}
                      />
                      <Form.Check
                        type="radio"
                        label="Other"
                        value="Other"
                        id="genderRadioOther"
                        name="gender"
                        className="me-5 radio-with-border"
                        checked={gender === "Other"}
                        onChange={(e) => setGender(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>

                {/* Date of Birth (dd/mm/yyyy) */}
                <div className="row mb-3">
                  <div className="col-md-5 d-flex align-items-center justify-content-start">
                    <Form.Group controlId="dateOfBirthLabel">
                      <Form.Label
                        htmlFor="dateOfBirth"
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        {/* ▸ Date of Birth (dd/mm/yyyy){" "} */}
                        ▸ Date of Birth {" "}

                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                    </Form.Group>
                  </div>
                  <div className="col-md-7 d-flex align-items-center justify-content-start">


                  <input
    type="date"
    id="dateOfBirth"
    value={dateOfBirth}
    className="form-control"
    max={new Date().toISOString().split('T')[0]}
    onChange={(e) => setDateOfBirth(e.target.value)}
    onClick={(e) => e.target.showPicker()} 
    style={{ cursor: "pointer", zIndex: "999" }}
  />



{/* 
                    <InputGroup style={{ width: "100%" }}>
                      <DatePicker
                        id="dateOfBirth"
                        selected={dateOfBirth}
                        onChange={(date) => setDateOfBirth(date)}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        placeholderText="Select date of birth"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        style={{ cursor: "pointer", zIndex: "999" }}
                        type="date"
                      />
                      <InputGroup.Text
                        onClick={() =>
                          document.getElementById("dateOfBirth").click()
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <BsCalendar size="0.7em" />
                      </InputGroup.Text>
                    </InputGroup> */}






                  </div>
                </div>
              </div>

              {/* {loading && (
                <div className="d-flex justify-content-center mt-3">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )} */}

              {loading && <Loader />}

              {/* Save&Exit and Next Buttons */}
              <div
                className="button-group text-center mb-1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {/* Back and Save & Exit and Next Buttons */}
                <div
                  className="button-group text-center mb-1"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {/* Back Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onClick={handleBack}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Back
                  </button>

                  {/* Save & Exit Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveAndExit}
                    style={{
                      backgroundColor: "#055837",
                      color: "#ffcc01",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                  >
                    Save & Exit
                  </button>

                  {/* Next Button */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#ffcc01",
                      color: "#055837",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      border: "none",
                      borderRadius: "5px",
                      marginTop: "30px",
                      marginBottom: "20px",
                      transition: "background-color 0.3s, color 0.3s",
                    }}
                    onClick={handleNext}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "#055837";
                      e.target.style.color = "#ffcc01";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffcc01";
                      e.target.style.color = "#055837";
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </Form>
          </Card.Body>

          <ConfirmationModal
            show={showConfirmationModal}
            onHide={handleConfirmationNo}
            onConfirm={handleConfirmationYes}
            title="Confirm Leaving"
            message="Are you sure you want to leave this page? You don't want to continue this new application process right now? If you proceed, you'll be redirected to the dashboard."
          />

          {backButtonClicked && (
            <ConfirmationModal
              show={showConfirmationBackModal}
              onHide={handleConfirmationBackNo}
              onConfirm={handleConfirmationBackYes}
              title="Confirm Navigation"
              message="Are you sure you want to go back to the Occupation Details form page?"
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default PersonalDetails;
